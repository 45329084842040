import { lighten } from "@material-ui/core/styles";
import { ThemeHelper } from "./ThemeHelper";
import { IColorProfile } from "./types";

export class OriginalTheme implements IColorProfile {
    public primary(isDarkMode: boolean) {
        return "#303335";
    }

    public secondary(isDarkMode: boolean) {
        return "#E15D1D";
    }

    public logo() {
        return "#E15D1D";
    }

    public backgroundDefault(isDarkMode: boolean) {
        return isDarkMode ? lighten(ThemeHelper.buildProjexBlack, 0.03) : "#FFFFFF";
    }

    public backgroundPaper(isDarkMode: boolean) {
        return isDarkMode ? lighten(ThemeHelper.buildProjexBlack, 0.03) : "#FFFFFF";
    }
}