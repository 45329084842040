// crafted from
//   https://stackoverflow.com/questions/63916900/how-to-properly-type-a-react-errorboundary-class-component-in-typescript
//   https://codepen.io/gaearon/pen/wqvxGa?editors=0010
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Typography from "@material-ui/core/Typography";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import * as React from "react";
import { LogoWithTextIcon } from "../../icons";
import { ErrorBoundaryProps, ErrorBoundaryState } from "./types";
import { currentTheme } from "../../../theme";

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
            expanded: false,
        };
    }

    //static getDerivedStateFromError(error: any) {
    //    // Update state so the next render will show the fallback UI.
    //    return { hasError: true };
    //}

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
            expanded: false,
        })
        // You can also log error messages to an error reporting service here
        //logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Paper
                    square
                    elevation={0}
                    style={{
                        overflowY: "scroll",
                        height: "100vh",
                    }}
                >
                    <Container
                        maxWidth="md"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: 48,
                            paddingBottom: 48,
                        }}
                    >
                        <LogoWithTextIcon
                            viewBox="0 0 2634 631"
                            style={{
                                height: 40,
                                width: 166.97,
                                marginBottom: 16,
                                color: currentTheme.secondary(false),
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="h1"
                            paragraph
                        >
                            An unexpected error has occurred
                        </Typography>
                        <Button
                            size="large"
                            variant="contained"
                            aria-label="refresh"
                            color="secondary"
                            startIcon={<RefreshRoundedIcon />}
                            onClick={() => window.location.reload()}
                        >
                            Reload
                        </Button>
                        <Accordion
                            elevation={4}
                            style={{ marginTop: 24, marginBottom: 16, }}
                            expanded={this.state.expanded}
                            onChange={(e, v) => this.setState(previous => ({ error: previous.error, errorInfo: previous.errorInfo, hasError: previous.hasError, expanded: v, }))}>
                            <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                                {`${this.state.expanded ? "Hide" : "Show"} details`}
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column" }}>
                                <Button
                                    variant="outlined"
                                    style={{ alignSelf: "flex-end" }}
                                    size="small"
                                    color="secondary"
                                    onClick={async () => {
                                        let s = "";
                                        if (this.state.error) {
                                            s += this.state.error.toString();
                                        }
                                        if (this.state.errorInfo) {
                                            s += this.state.errorInfo.componentStack;
                                        }
                                        s += "\nUrl: " + window.location.toString();
                                        s += "\nTimestamp: " + new Date().toUTCString();
                                        s += "\nUseragent: " + navigator.userAgent;
                                        try {
                                            await navigator.clipboard.writeText(s);
                                            console.log("Text copied to clipboard")
                                        }
                                        catch (ex: any) {
                                            console.warn("Failed to copy text to clipboard", ex);
                                        }
                                    }}
                                >
                                    Copy to clipboard
                                </Button>
                                {this.state.error &&
                                    <Typography variant="subtitle2">
                                        {this.state.error.toString()}
                                    </Typography>
                                }
                                {this.state.errorInfo &&
                                    <Typography variant="body2" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                                        {this.state.errorInfo.componentStack}
                                    </Typography>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Container>
                </Paper>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export { ErrorBoundary };