import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FadeTransition } from "../FadeTransition";
import { GrowTransition } from "../GrowTransition";
import { SlideDownTransition, SlideLeftTransition, SlideRightTransition, SlideUpTransition } from "../SlideTransition";
import { ZoomTransition } from "../ZoomTransition";
import { ResponsiveModalDialogTransition, ResponsiveModalDialogVerticalAlignment, StyleProps, Title } from "./types";

const useStyles = makeStyles(() =>
    createStyles({
        scrollPaperTop: {
            alignItems: "flex-start",
            marginTop: 80,
            maxHeight: `calc(100% - 80px)`, // calc(100% - 64px) is default
        },
        scrollPaperBottom: {
            alignItems: "flex-end",
        },
        scrollPaperStretch: {
            alignItems: "stretch",
        },
        scrollPaperCenter: {
            alignItems: "center",
            transform: "none",
        },
        preserveWhitespace: {
            whiteSpace: "pre-wrap",
        },
    }),
);

export const useDialogStyles = makeStyles<Theme, StyleProps>((theme) =>
    createStyles({
        // styles required for react-beautiful-dnd
        dialogPaper: {
            overflow: "visible", // library does not support dragging within nested scroll containers
            //transform: (props => props.movable ? "none !important" : "translate(0,0)") // mui sets translate(0,0) which upsets horizontal shift of items being dragged
        },
        dialog: {
            textOverflow: "ellipsis",
            zIndex: (props => props.zIndex ? props.zIndex : theme.zIndex.modal),
        },
        dialogActions: {
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(2),
        },
        dialogContent: {
            marginBottom: (props => props.noMargin ? 0 : props.mobileFullScreen ? 0 : theme.spacing(1)),
            paddingTop: (props => props.noMargin ? 0 : props.mobileFullScreen ? theme.spacing(2) : theme.spacing(1)),
            paddingBottom: (props => props.noMargin ? 0 : props.mobileFullScreen ? theme.spacing(2) : theme.spacing(1)),
            paddingLeft: (props => props.noMargin ? 0 : props.mobileFullScreen ? theme.spacing(1.5) : theme.spacing(3)),
            paddingRight: (props => props.noMargin ? 0 : props.mobileFullScreen ? theme.spacing(1.5) : theme.spacing(3)),
            display: "flex",
            flexDirection: "column",
        },
        dialogContentText: {
            whiteSpace: "pre-wrap",
            paddingLeft: (props => props.noMargin ? theme.spacing(3) : 0),
            paddingRight: (props => props.noMargin ? theme.spacing(3) : 0),
            paddingTop: (props => props.noMargin ? theme.spacing(1) : 0),
            marginTop: (props => props.mobileFullScreen ? 0 : theme.spacing(-1)),
            color: theme.palette.text.primary,
            wordBreak: "break-word",
            userSelect: "none",
        },
        dialogContentInner: {
            display: "flex",
            flexDirection: "column",
        },
    }),
);
/**
Positioning concept from https://stackoverflow.com/a/61094451/1014312
*/
export const GetScrollPaperClass = (fullScreen: boolean, verticalAlignment: ResponsiveModalDialogVerticalAlignment) => {
    const classes = useStyles();
    switch (verticalAlignment) {
        case "top": return fullScreen ? classes.scrollPaperCenter : classes.scrollPaperTop;
        case "bottom": return classes.scrollPaperBottom;
        case "stretch": return classes.scrollPaperStretch;
        default: return classes.scrollPaperCenter;
    }
}

export const GetTransition = (
    mobileFullScreen: boolean, fullScreenTransition: ResponsiveModalDialogTransition, transition: ResponsiveModalDialogTransition) => {
    switch (mobileFullScreen ? fullScreenTransition : transition) {
        case "fade": return FadeTransition;
        case "grow": return GrowTransition;
        case "zoom": return ZoomTransition;
        case "slideUp": return SlideUpTransition;
        case "slideDown": return SlideDownTransition;
        case "slideLeft": return SlideLeftTransition;
        case "slideRight": return SlideRightTransition;
    }
}

export const getTitle = (title: Title | undefined): string | undefined => {
    if (!title) return undefined;
    if (typeof title === "string") return title;
    return title.title;
}

export const getSubtitle = (title: Title | undefined): string | undefined => {
    if (!title) return undefined;
    if (typeof title === "string") return undefined;
    return title.subtitle;
}