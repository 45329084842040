import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { ThemeHelper } from "../../../theme";
import { SvenTooltipProps, Variant } from "./types";

interface StyleProps {
    variant: Variant | undefined;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
    const getBgColor = (variant: Variant | undefined) => {
        if (!variant) return ThemeHelper.tooltipBackgroundColor;
        switch (variant) {
            case "error": return theme.palette.error.main;
            case "info": return theme.palette.info.main;
            case "success": return theme.palette.success.main;
            case "warning": return theme.palette.warning.main;
        }
    }

    const getColor = (variant: Variant | undefined) => {
        if (!variant) return theme.palette.common.white;
        switch (variant) {
            case "error": return theme.palette.error.contrastText;
            case "info": return theme.palette.info.contrastText;
            case "success": return theme.palette.success.contrastText;
            case "warning": return theme.palette.warning.contrastText;
            default: return ThemeHelper.tooltipBackgroundColor;
        }
    }

    return createStyles({
        tooltip: {
            backgroundColor: (props => getBgColor(props.variant)),
            color: (props => getColor(props.variant)),
            // maxWidth: "100%",
            maxWidth: 400,
        },
        arrow: {
            "&::before": {
                backgroundColor: (props => getBgColor(props.variant)),
            }
        },
    })
}
);

export const SvenTooltip = ({ variant, title, classes, ...rest }: SvenTooltipProps) => {
    const c = useStyles({ variant: variant });

    if (typeof (title) === "string" && title === "") return <Tooltip {...rest} title={title} />;

    return (
        <Tooltip
            classes={{
                ...classes,
                tooltip: c.tooltip,
                arrow: c.arrow,
            }}
            {...rest}
            title={typeof (title) === "string" ? (
                <Typography
                    variant="caption"
                    style={{ whiteSpace: "pre-wrap" }}
                >
                    {title}
                </Typography>
            ) : title}

        />
    );
}