import * as React from "react";
import { ISessionContext, SessionStatus } from "./types";
import { NoProviderError } from "../../types";

const defaultData: { status: SessionStatus, workspaceId: string | undefined } = { status: "authenticating", workspaceId: undefined }

const SessionContext = React.createContext<ISessionContext>({
    // session: undefined,
    data: defaultData,
    destination: null,
    signIn: () => { throw new NoProviderError() },
    signOut: () => { throw new NoProviderError() },
    changePassword: () => { throw new NoProviderError() },
    changeWorkspace: () => { throw new NoProviderError() },
    getValidAccessToken: () => { throw new NoProviderError() },
    trySilentSignOut: () => { throw new NoProviderError() },
});

const useSession = () => React.useContext(SessionContext);

export { useSession, SessionContext, defaultData }