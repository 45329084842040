import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Paper from "@material-ui/core/Paper";
import * as React from "react";
import { Prompt, useLocation } from "react-router-dom";
import { ThemeHelper } from "../../../theme";
import { useDeviceMeasurements } from "../../../hooks/useDeviceMeasurements";
import { SubmitButton } from "../SubmitButton";
import { SvenBackdrop } from "../SvenBackdrop";
import { DesktopDialogTitle } from "./DesktopDialogTitle";
import { DraggablePaper } from "./DraggablePaper";
import { ResponsiveModalAppBarForForm } from "./ResponsiveModalAppBarForForm";
import { FormRef, ResponsiveModalDialogWithFormProps } from "./types";
import { GetScrollPaperClass, GetTransition, useDialogStyles } from "./utils";
import { useTheme } from "@material-ui/core/styles";

const leavingMessage = "Changes that you made may not be saved.";

const ResponsiveModalDialogWithForm = React.forwardRef<FormRef, ResponsiveModalDialogWithFormProps>(({
    additionalHeaderComponent,
    allowDesktopFullScreen = true,
    allowPhoneFullScreen = true,
    autofocusSubmitButton,
    buttonMinWidth = ThemeHelper.formButtonMinWidth,
    cancelButtonText = "Cancel",
    caption,
    captionStyle,
    children,
    close,
    dialogContentStyle,
    doNotCloseOnBackdropClick,
    doNotUsePrompt,
    fullScreenTransition = "slideLeft",
    fullWidth = true,
    helpUrl,
    icon,
    isOpen,
    isSubmitButtonEnabled = true,
    maxWidth = "sm",
    movable = true,
    noMargin,
    onClosing,
    onDesktopFullScreenChange,
    onEntered,
    onMobileFullScreenChange,
    onSubmit,
    disablePortal,
    showCancelButton = true,
    showCloseButton = true,
    showDividers,
    showSubmitButton = true,
    style,
    submitButtonColor = "secondary",
    submitButtonText = "Submit",
    title,
    transition = "zoom",
    transitionDuration,
    useBackIconForMobileFullscreenCloseButton,
    verticalAlignment = "center",
    zIndex,
}: ResponsiveModalDialogWithFormProps, ref) => {
    const { maybePhone, width } = useDeviceMeasurements();
    const theme = useTheme();
    const location = useLocation();

    const mobileFullScreen = (maybePhone || width < 768) && allowPhoneFullScreen;// useMediaQuery(theme.breakpoints.down(breakpointForMobileFullScreen)) && allowMobileFullScreen;

    const classes = useDialogStyles({
        movable: movable,
        mobileFullScreen: mobileFullScreen,
        noMargin: noMargin,
        zIndex: zIndex,
    });

    const [desktopFullScreen, setDesktopFullscreen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    // track if the component is still mounted
    const isMountedRef = React.useRef(true);
    React.useEffect(() => () => { isMountedRef.current = false }, [])

    const [isDirty, setIsDirty] = React.useState(false);
    const locationRef = React.useRef<string | undefined>(undefined);

    // track the location from when the dialog was opened and if it changes then close the dialog
    React.useEffect(() => {
        if (isOpen) {
            const newLocation = `${location.pathname}${location.search}${location.hash}`;
            if (locationRef.current && newLocation !== locationRef.current) close();
            locationRef.current = newLocation;
        }
    }, [location, isOpen, close]);

    // on closing clear the locationRef
    React.useEffect(() => {
        if (!isOpen) {
            locationRef.current = undefined;
        }
    }, [isOpen]);

    // wire up event handlers
    React.useEffect(() => {
        const handleBeforeUnloadEvent = (e: BeforeUnloadEvent) => {
            if (isOpen && isDirty) {
                e.preventDefault();
                e.returnValue = leavingMessage;
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnloadEvent);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
        }
    }, [isOpen, isDirty]);

    // methods that parent components can call via the ref
    React.useImperativeHandle(ref,
        () => ({
            setDirtyStatus(value: boolean) {
                setIsDirty(value);
            },
            dirtyStatus() {
                return isDirty;
            },
            manuallySetIsSubmitting(value: boolean) {
                setIsSubmitting(value);
            },
        })
    );

    //const isDirtyRef = React.useRef<boolean>(false);

    const handleChange = React.useCallback(async (e: React.FormEvent) => {
        // any form input element rendered with the data-donottriggerformchange="true" will
        // not cause form dirty
        const value = (e.target as HTMLElement).getAttribute("data-donottriggerformchange");
        if (value === "true") return;

        setIsDirty(true);
    }, []);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (onSubmit) {
            setIsSubmitting(true);
            await onSubmit();
            if (isMountedRef.current) setIsSubmitting(false);
        }
    }, [onSubmit]);

    // undo full screen on open
    React.useEffect(() => setDesktopFullscreen(false), [isOpen]);

    const handleEnterFullScreen = React.useCallback(() => setDesktopFullscreen(() => true), []);

    const handleExitFullScreen = React.useCallback(() => setDesktopFullscreen(() => false), []);

    const handleClose = React.useCallback(async (e: any, reason: "escapeKeyDown" | "backdropClick") => {
        if (isSubmitting) return;

        if (reason === "backdropClick" && isDirty) return;

        if (reason === "backdropClick" && doNotCloseOnBackdropClick) return;

        if (onClosing) {
            const result = await onClosing();
            if (!result) return;
        }

        close();
    }, [close, isSubmitting, isDirty, onClosing, doNotCloseOnBackdropClick])

    // perform the callback function if defined once we have entered the dialog
    const onEnteredInternal = (node: HTMLElement, isAppearing: boolean) => onEntered ? onEntered() : () => { };

    // perform the callback function if defined when the screen toggles between desktop floating and desktop full screen
    React.useEffect(() => { if (onDesktopFullScreenChange) onDesktopFullScreenChange(desktopFullScreen) }, [onDesktopFullScreenChange, desktopFullScreen]);

    // perform the callback function if defined when the screen toggles between desktop and mobile full screen
    React.useEffect(() => { if (onMobileFullScreenChange) onMobileFullScreenChange(mobileFullScreen) }, [onMobileFullScreenChange, mobileFullScreen]);

    React.useEffect(() => { setIsDirty(false) }, [isOpen]);

    const onCloseInternal = React.useCallback(async () => {
        if (onClosing) {
            const result = await onClosing();
            if (!result) return;
        }
        close();
    }, [close, onClosing]);

    return (
        <Dialog
            classes={{ paper: classes.dialogPaper, scrollPaper: GetScrollPaperClass(mobileFullScreen || desktopFullScreen, verticalAlignment) }}
            className={classes.dialog}
            fullScreen={mobileFullScreen || desktopFullScreen}
            fullWidth={fullWidth}
            disablePortal={disablePortal}
            maxWidth={mobileFullScreen || desktopFullScreen ? false : maxWidth}
            onClose={handleClose}
            open={isOpen}
            style={style}
            PaperComponent={movable ? DraggablePaper : Paper}
            scroll={"paper"}
            transitionDuration={transitionDuration ? transitionDuration : { enter: theme.transitions.duration.enteringScreen, exit: theme.transitions.duration.leavingScreen }}
            TransitionComponent={GetTransition(mobileFullScreen, fullScreenTransition, transition)}
            TransitionProps={{ onEntered: onEnteredInternal }}
        >
            <Prompt
                when={isDirty && !doNotUsePrompt}
                message="You have unsaved changes. Click OK to discard your changes or Cancel to continue editing."
            />
            {mobileFullScreen ? (
                <ResponsiveModalAppBarForForm
                    additionalComponent={additionalHeaderComponent}
                    helpUrl={helpUrl}
                    icon={icon}
                    isSubmitButtonEnabled={isSubmitButtonEnabled}
                    onClose={onCloseInternal}
                    onSubmitClick={handleSubmit}
                    showCloseButton={showCloseButton}
                    showSubmitButton={showSubmitButton}
                    submitButtonText={submitButtonText}
                    title={title}
                    useBackIconForMobileFullscreenCloseButton={useBackIconForMobileFullscreenCloseButton}
                />
            ) : (
                <DesktopDialogTitle
                    helpUrl={helpUrl}
                    icon={icon}
                    additionalComponent={additionalHeaderComponent}
                    allowDesktopFullScreen={allowDesktopFullScreen}
                    desktopFullScreen={desktopFullScreen}
                    movable={movable}
                    onClose={onCloseInternal}
                    onEnterFullScreen={handleEnterFullScreen}
                    onExitFullScreen={handleExitFullScreen}
                    showCloseButton={showCloseButton}
                    title={title}
                />
            )}

            <DialogContent dividers={showDividers} className={classes.dialogContent} style={dialogContentStyle}>
                {caption &&
                    <DialogContentText className={classes.dialogContentText} style={captionStyle}>
                        {caption}
                    </DialogContentText>
                }
                <form
                    noValidate
                    onSubmit={(e) => handleSubmit(e)}
                    onChange={(e) => handleChange(e)}
                    autoComplete="chrome-off"
                    style={{ flexGrow: 1 }}
                >
                    {/*<Box mb={0} mt={mobileFullScreen ? 2 : 1}>*/}
                    {children}
                    {/*</Box>*/}
                    <input
                        type="submit"
                        hidden
                        aria-hidden="true"
                    />
                </form>
            </DialogContent>

            {!mobileFullScreen && (showCancelButton || showSubmitButton) && (
                <DialogActions className={classes.dialogActions}>
                    {showCancelButton &&
                        <Button
                            aria-label={cancelButtonText}
                            type="button"
                            style={{ minWidth: buttonMinWidth }}
                            onClick={onCloseInternal}
                            color="default"
                            variant="text"
                        >
                            {cancelButtonText}
                        </Button>
                    }
                    {showSubmitButton &&
                        <SubmitButton
                            autofocus={autofocusSubmitButton}
                            disabled={!isSubmitButtonEnabled}
                            text={submitButtonText}
                            isBusy={isSubmitting}
                            color={submitButtonColor}
                            style={{ minWidth: buttonMinWidth }}
                            onSubmit={handleSubmit}
                        />
                    }
                </DialogActions>
            )}

            <SvenBackdrop isBusy={isSubmitting} />
        </Dialog>

    );
});

export { ResponsiveModalDialogWithForm };