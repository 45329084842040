import { createSvgIcon } from "@material-ui/core/utils";
import * as React from "react";
import { currentTheme } from "../../theme";

export default createSvgIcon(
    <React.Fragment>
        <circle
            cx="320"
            cy="320"
            r="280"
            style={{ fill: currentTheme.logo() }}
        />
        <g style={{ fill: "#FFFFFF", strokeWidth: 1, stroke: currentTheme.logo() }}>
            <path d="M259.705,359.451v-72.57l60.476-36.289l60.476,36.289v72.57l-60.476,36.288L259.705,359.451z" />
            <path d="M115.655,359.451v-72.57l60.476-36.289l60.476,36.289v72.57l-60.476,36.288L115.655,359.451z" />
            <path d="M187.679,226.485v-72.569l60.477-36.289l60.484,36.289v72.569l-60.484,36.289L187.679,226.485z" />
            <path d="M331.728,226.485v-72.569l60.476-36.289l60.477,36.289v72.569l-60.477,36.289L331.728,226.485z" />
            <path d="M403.753,359.451v-72.57l60.477-36.289l60.476,36.289v72.57l-60.476,36.288L403.753,359.451z" />
            <path d="M331.728,492.424v-72.577l60.476-36.289l60.477,36.289v72.577l-60.477,36.28L331.728,492.424z" />
            <path d="M187.679,492.424v-72.577l60.477-36.289l60.484,36.289v72.577l-60.484,36.28L187.679,492.424z" />
        </g>
    </React.Fragment>
    , "Logo");