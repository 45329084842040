import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { BackButton } from "../BackButton";
import { SvenIconButton } from "../SvenIconButton";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { DialogIcon } from "./DialogIcon";
import { ResponsiveModalAppBarProps } from "./types";
import * as React from "react";
import { getSubtitle, getTitle } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
        },
        titleContainer: {
            flex: 1,
            marginLeft: theme.spacing(1),
        },
        title: {
            userSelect: "none",
        },
    }),
);

const ResponsiveModalAppBar = ({
    additionalComponent,
    helpUrl,
    icon,
    onClose,
    showCloseButton,
    title,
    useBackIconForMobileFullscreenCloseButton,
}: ResponsiveModalAppBarProps) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position="relative">
                <Toolbar>
                    {showCloseButton && !useBackIconForMobileFullscreenCloseButton &&
                        <SvenIconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            text="Close"
                            aria-label="close"
                        >
                            <CloseRoundedIcon />
                        </SvenIconButton>
                    }
                    {showCloseButton && useBackIconForMobileFullscreenCloseButton &&
                        <BackButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            text="Back"
                            aria-label="back"
                        />
                    }
                    {icon &&
                        <DialogIcon icon={icon} />
                    }
                    <div className={classes.titleContainer}>
                        <Typography
                            variant="h6"
                            className={classes.title}
                            noWrap
                        >
                            {getTitle(title)}
                        </Typography>
                        {getSubtitle(title) &&
                            <Typography
                                variant="caption"
                                className={classes.title}
                                noWrap
                            >
                                {getSubtitle(title)}
                            </Typography>
                        }
                    </div>
                    {additionalComponent}
                </Toolbar>
            </AppBar>
            {helpUrl &&
                <SvenIconButton
                    color="secondary"
                    text="Help"
                    showText={true}
                    style={{ alignSelf: "flex-end" }}
                    flexDirection="row-reverse"
                    onClick={() => {
                        const link = document.createElement("a");
                        link.href = helpUrl;
                        link.setAttribute("target", "_blank");
                        link.setAttribute("rel", "noopener");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                >
                    <HelpOutlineRoundedIcon />
                </SvenIconButton>
            }
        </React.Fragment>
    );
}

export { ResponsiveModalAppBar };