import { generatePath } from "react-router";
import { TaskAssociationFilterId, TaskFilterId } from "../config/taskConfig";
import { TaskTableDefinition } from "../table-definitions/TaskTableDefinition";
import { SortDirection } from "../types";

export class UrlHelper {
    public static readonly calendarUrl = "/calendar";
    public static readonly changePasswordUrl = "/change-password";
    public static readonly channelsUrl = "/channels";
    public static readonly createAccountUrl = "/create-account";
    public static readonly devUrl = "/dev";
    public static readonly drawingsUrl = "/drawings";
    public static readonly estimateSettingsUrl = "/estimate-settings";
    public static readonly estimatesUrl = "/estimates";
    public static readonly filesUrl = "/files";
    public static readonly activityUrl = "/activity";
    public static readonly forgotPasswordUrl = "/forgot-password";
    public static readonly ganttUrl = "/gantt";
    public static readonly homeUrl = "/home";
    public static readonly verifyInviteUrl = "/invite";
    public static readonly loginUrl = "/login";
    public static readonly projectsUrl = "/projects";
    public static readonly settingsAddressBookUrl = "/settings/address-book";
    public static readonly settingsNotificationsUrl = "/settings/notifications";
    public static readonly settingsTagsUrl = "/settings/tags";
    public static readonly settingsGeneralUrl = "/settings/general";
    public static readonly settingsPlaygroundsUrl = "/settings/playground";
    public static readonly settingsOrganizationUrl = "/settings/organization";
    public static readonly settingsProjectsUrl = "/settings/projects";
    public static readonly settingsUrl = "/settings";
    public static readonly settingsUsersUrl = "/settings/users";
    public static readonly tasksUrl = "/tasks";
    public static readonly verifyAccountUrl = "/verify-account";
    public static readonly verifyEmailUrl = "/verify-email";

    /** match a tab route for a given project */
    public static readonly settingsTagsUrlRouteMatch = `${this.settingsTagsUrl}/:tag`;
    public static readonly channelUrlRouteMatch = `${this.channelsUrl}/:channelId`;
    public static readonly settingsUsersUrlRouteMatch = `${this.settingsUsersUrl}/:memberType`;

    public static readonly projectUrlRouteMatch = `${this.projectsUrl}/:projectId`;
    public static readonly projectAndTabUrlRouteMatch = `${this.projectsUrl}/:projectId/:tab`;
    public static readonly projectAndTabAndItemUrlRouteMatch = `${this.projectsUrl}/:projectId/:tab/:itemId`;

    public static readonly drawingUrlRouteMatch = `${this.drawingsUrl}/:drawingId`;
    public static readonly estimateUrlRouteMatch = `${this.estimatesUrl}/:estimateId`;

    public static getSettingsUsersUrl = (memberType: UrlHelper.MemberType): string => {
        return generatePath(this.settingsUsersUrlRouteMatch, {
            memberType: memberType,
        });
    }
    public static getSettingsTagsUrl = (tag: UrlHelper.Tag): string => {
        return generatePath(this.settingsTagsUrlRouteMatch, {
            tag: tag,
        });
    }
    public static getChannelUrl = (channelId: string): string => {
        return generatePath(this.channelUrlRouteMatch, {
            channelId: channelId,
        });
    }

    public static getDrawingUrl = (drawingId: string): string => {
        return generatePath(this.drawingUrlRouteMatch, {
            drawingId: drawingId,
        });
    }

    public static getEstimateUrl = (estimateId: string): string => {
        return generatePath(this.estimateUrlRouteMatch, {
            estimateId: estimateId,
        });
    }

    //public static projectCalendarUrl = (projectId: string): string => {
    //    return generatePath(this.projectAndTabRouteMatch, {
    //        projectId: projectId,
    //        tab: "calendar",
    //    });
    //}

    public static getProjectRootFolderUrl = (projectId: string): string => {
        // project root
        return generatePath(this.projectAndTabUrlRouteMatch, {
            projectId: projectId,
            tab: "folders",
        });
    }

    public static getProjectActivityUrl = (projectId: string): string => {
        // project root
        return generatePath(this.projectAndTabUrlRouteMatch, {
            projectId: projectId,
            tab: "activity",
        });
    }
    public static getProjectFolderUrl = (projectId: string, folderId: string): string => {
        //some other project sub-folder
        return generatePath(this.projectAndTabAndItemUrlRouteMatch, {
            projectId: projectId,
            tab: "folders",
            itemId: folderId,
        });
    }
    public static getProjectGanttUrl = (projectId: string): string => {
        return generatePath(this.projectAndTabUrlRouteMatch, {
            projectId: projectId,
            tab: "gantt",
        });
    }

    public static buildQuerystring = (items: { key: string, value: string | undefined }[]): string | undefined => {
        let querystring = "";
        items.forEach(item => {
            if (item.value) {
                if (querystring.length > 0)
                    querystring += "&";

                querystring += `${item.key}=${item.value}`
            }
        });

        if (querystring.length === 0)
            return undefined;

        return "?" + querystring;
    }

    public static calendarUrlExt = (
        taskAssociationFilterId: TaskAssociationFilterId | undefined = undefined
    ): string => {
        const params = [
            { key: "a", value: taskAssociationFilterId, },
        ]

        const querystring = this.buildQuerystring(params);
        return querystring ? this.calendarUrl + querystring : this.calendarUrl;
    }

    public static tasksUrlExt = (
        filterId: TaskFilterId | undefined = undefined,
        sortColumn: TaskTableDefinition.ColumnName | undefined = undefined,
        sortDirection: SortDirection | undefined = undefined,
        taskAssociationFilterId: TaskAssociationFilterId | undefined = undefined
    ): string => {
        const params = [
            { key: "f", value: filterId, },
            { key: "s", value: this.getSortValue(sortColumn, sortDirection) },
            { key: "a", value: taskAssociationFilterId, },
        ]

        const querystring = this.buildQuerystring(params);
        return querystring ? this.tasksUrl + querystring : this.tasksUrl;
    }

    private static getSortValue = (sortColumn: TaskTableDefinition.ColumnName | undefined, sortDirection: SortDirection | undefined) => {
        let sortValue: string | undefined = undefined;
        if (sortColumn) {
            sortValue = sortColumn;
            if (sortDirection) {
                sortValue += ":" + sortDirection;
            }
        }
        return sortValue;
    }

    public static getProjectCalendarUrl = (
        projectId: string,
        taskAssociationFilterId: TaskAssociationFilterId | undefined = undefined
    ): string => {
        const path = generatePath(this.projectAndTabUrlRouteMatch, {
            projectId: projectId,
            tab: "calendar",
        });

        const params = [
            { key: "a", value: taskAssociationFilterId, },
        ]

        const querystring = this.buildQuerystring(params);
        return querystring ? path + querystring : path;
    }

    public static projectTasksUrl = (
        projectId: string,
        filterId: TaskFilterId | undefined = undefined,
        sortColumn: TaskTableDefinition.ColumnName | undefined = undefined,
        sortDirection: SortDirection | undefined = undefined,
        taskAssociationFilterId: TaskAssociationFilterId | undefined = undefined
    ): string => {
        const path = generatePath(this.projectAndTabUrlRouteMatch, {
            projectId: projectId,
            tab: "tasks",
        });

        const params = [
            { key: "f", value: filterId, },
            { key: "s", value: this.getSortValue(sortColumn, sortDirection) },
            { key: "a", value: taskAssociationFilterId, },
        ]

        const querystring = this.buildQuerystring(params);
        return querystring ? path + querystring : path;
    }

    public static getProjectOverviewUrl = (projectId: string): string => {
        return generatePath(this.projectUrlRouteMatch, {
            projectId: projectId,
        });
    }

    /**
     * Generate an email url e.g. mailto:bob@aol.com
     * @param email
     */
    public static mailto = (email: string | undefined): string => "mailto:" + email;

    /**
     * Generate a tel url e.g. tel:0123456789
     * @param phone
     */
    public static tel = (phone: string | undefined): string => {
        // regex https://stackoverflow.com/a/5963202/1014312
        // replace spaces with empty strings

        // + is only used for international numbers
        // we will assume all numbers are local and rely on
        // users to enter +44 or 0044 at the start of
        // an international number
        //return "tel:+" + phone?.replace(/\s+/g, "");
        return "tel:" + phone?.replace(/\s+/g, "");
    }

    /**
     * Generate an sms url e.g. sms:0123456789
     * @param phone
     */
    public static sms = (phone: string | undefined): string => {
        // regex https://stackoverflow.com/a/5963202/1014312
        // replace spaces with empty strings
        return "sms:" + phone?.replace(/\s+/g, "");
    }
}

export module UrlHelper {
    export type Tag = "contact" | "project" | "task" | undefined;
    export type MemberType = "member" | "guest" | "all";
}