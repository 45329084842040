import { ApiClientBase } from "../services/ApiClient/ApiClientBase";
import { ImageTransformData, MessageAnnotation, ProjectProgressData, SlideshowData } from "../types";

export class JsonHelper {
    /**
     * performs a recursive in-place replacement of strings to their date objects
     * @param obj
     */
    public static convertStringsToDates = (obj: any) => {
        const keys = Object.keys(obj);
        keys.forEach(key => {
            const value = Reflect.get(obj, key);
            if (typeof value === "string" && ApiClientBase.apiDateFormat.test(value)) {
                Reflect.set(obj, key, new Date(value));
            } else if (value && typeof value === "object") {
                this.convertStringsToDates(value);
            }
        })
    }

    public static clone = <Type>(obj: Type) => {
        const cloned = JSON.parse(JSON.stringify(obj)) as Type;
        this.convertStringsToDates(cloned);
        return cloned;
    }

    public static serializeProjectProgressData = (projectProgressData: ProjectProgressData): string => {
        const json = JSON.stringify(projectProgressData, null, 2);

        return json;
    }

    public static serializeSlideshowData = (slideshowData: SlideshowData): string => {
        const json = JSON.stringify(slideshowData, null, 2);

        return json;
    }

    public static serializeMessageAnnotation = (messageAnnotation: MessageAnnotation): string => {
        const json = JSON.stringify(messageAnnotation, (key, value) => {
            if (key === "scopes")
                return undefined;

            if (key === "id")
                return undefined;

            if (key === "memberId")
                return undefined;

            return value;
        }, 2);
        return json;
    }

    public static serializeImageTransformData = (imageTransformData: ImageTransformData[]): string => {
        const json = JSON.stringify(imageTransformData, null, 2);
        return json;
    }

    public static deserializeImageTransformData = (json: string): ImageTransformData[] => {
        try {
            return JSON.parse(json) as ImageTransformData[];
        }
        catch (ex: any) {
            console.warn("Failed to deserialize ImageTransformData", ex);
        };
        return [];
    }

    public static deserializeMessageAnnotation = (json: string): MessageAnnotation | undefined => {
        try {
            const messageAnnotation = JSON.parse(json, (key, value) => {
                if (key === "size" && !value)
                    return 16;

                if (key === "size" && value === 0)
                    return 16;

                return value;
            }) as MessageAnnotation;

            return messageAnnotation;
        }
        catch (ex: any) {
            console.warn("Failed to deserialize MessageAnnotation", ex);
        };
    }
}