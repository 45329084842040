import { Jwt } from "../services/ApiClient/Schema";

export class TokenHelper {
    public static decode = (token: string): Jwt | undefined => {
        try {
            return JSON.parse(atob(token.split('.')[1]), function (this, key, value) {
                if (key === "exp" && value) {
                    return new Date(Number(value) * 1000);
                } else if (key === "iat" && value) {
                    return new Date(Number(value) * 1000);
                } else if (key === "http://schemas.hbxl.co.uk/2020/09/aid") {
                    (this as Jwt).appId = value;
                } else if (key === "http://schemas.hbxl.co.uk/2020/09/wid") {
                    (this as Jwt).workspaceId = value;
                }
                return value;
            });
        }
        catch (ex: any) {
            console.error("Could not decode token. Token=" + token, ex);
        }

    }
}
