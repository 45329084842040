import { createSvgIcon } from "@material-ui/core/utils";
import * as React from "react";
import { ThemeHelper } from "../../theme";

//* This is a direct copy of CloseFullscreenRounded from MUI v5. Delete this file when we have upgraded * /
export default createSvgIcon(
    <React.Fragment>
        <path d="M16.01 11H5c-.55 0-1 .45-1 1s.45 1 1 1h11.01v1.79c0 .45.54.67.85.35l2.78-2.79c.19-.2.19-.51 0-.71l-2.78-2.79c-.31-.32-.85-.09-.85.35V11z" transform="translate(4,6)" />
        <rect x="1" y="4" rx="1" ry="1" width="22" height="9" fill={ThemeHelper.defaultGanttTaskColor} strokeWidth="2" stroke="currentcolor" strokeLinecap="round" />
    </React.Fragment>
    , "AutomaticScheduling");