import { useSnackbar } from "notistack";
import * as React from "react";
import { SnackbarHelper } from "../../helpers/SnackbarHelper";
import { ContactResponse, EntityNotificationEventName, MemberDeactivatedResponse, MemberResponse } from "../../services/ApiClient/Schema";
import { NoProviderError, VerbosityLevel } from "../../types";
import { useMessageBox } from "../MessageBoxContext";
import { AuthenticatedProviderProps, IAuthenticatedContext } from "./types";
import { CoreCloudManager } from "../../services/CloudManager/CoreCloudManager";
import { useSession } from "../SessionContext";
import { ErrorHelper } from "../../helpers/ErrorHelper";

const AuthenticatedContext = React.createContext<IAuthenticatedContext>({
    authedMember: undefined,
    isLoading: false,
    requestChangeEmail: () => { throw new NoProviderError() },
});

const AuthenticatedProvider: React.FC<AuthenticatedProviderProps> = ({ children }) => {
    const messageBox = useMessageBox();
    const { data, signOut, getValidAccessToken } = useSession();
    const { enqueueSnackbar } = useSnackbar();

    const [authedMember, setAuthedMember] = React.useState<MemberResponse | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState(true);
    const workspaceIdRef = React.useRef<string | undefined>(undefined);

    const coreCloudManager = React.useRef<CoreCloudManager>();

    React.useEffect(() => {
        coreCloudManager.current = new CoreCloudManager(getValidAccessToken);
    }, [getValidAccessToken]);

    /** wire up event handler for MemberDeactivated */
    React.useEffect(() => {
        function handleMemberDeactivatedEvent(e: Event) {
            const asyncFunction = async () => {
                if (authedMember) {
                    const response = (e as CustomEvent<MemberDeactivatedResponse>).detail;
                    if (response.memberId === authedMember.id) {
                        await messageBox({
                            title: "Access denied",
                            caption: "Your access to this workspace has been turned off.\n\nYou will now be logged out.",
                            showCancelButton: false,
                            submitButtonColor: "primary",
                            icon: "error",
                            submitButtonText: "Close",
                        });
                    }
                    await signOut();
                }
            }
            asyncFunction();
        }

        document.addEventListener(EntityNotificationEventName.MemberDeactivated, handleMemberDeactivatedEvent);
        return () => {
            document.removeEventListener(EntityNotificationEventName.MemberDeactivated, handleMemberDeactivatedEvent);
        }
    }, [authedMember, messageBox, signOut]);

    // try to fetch user on 1st load
    React.useEffect(() => {
        const asyncFunction = async () => {
            if (data.workspaceId && coreCloudManager.current) {
                if (data.workspaceId !== workspaceIdRef.current) {
                    const result = await coreCloudManager.current.getUser();
                    if (result.isOk) {
                        workspaceIdRef.current = data.workspaceId;
                        setAuthedMember(result.response);
                    } else if (!result.isAborted) {
                        if (result.exception?.title === "Token Error") {
                            // keep quiet
                        } else {
                            const errorMessage = ErrorHelper.getErrorMessageForSnackbar("Failed to get user.", result.exception);
                            SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, errorMessage, "error");
                        }
                    }
                }
            }
            setIsLoading(false);
        }
        asyncFunction();
    }, [enqueueSnackbar, data]);

    /** wire up event handlers */
    React.useEffect(() => {
        function handleMemberUpdatedEvent(e: Event) {
            const m = (e as CustomEvent<MemberResponse>).detail;
            setAuthedMember(previous => {
                if (!previous) return previous;
                if (m.id === previous.id) {
                    return m;
                } else {
                    return previous;
                }
            });
        }

        function handleContactUpdatedEvent(e: Event) {
            const c = (e as CustomEvent<ContactResponse>).detail;

            setAuthedMember(previous => {
                if (previous && previous.id === c.id) {
                    previous.contact = c;
                    return previous;
                } else {
                    return previous;
                }
            });
        }
        document.addEventListener(EntityNotificationEventName.MemberUpdated, handleMemberUpdatedEvent);
        document.addEventListener(EntityNotificationEventName.ContactUpdated, handleContactUpdatedEvent);
        return () => {
            document.removeEventListener(EntityNotificationEventName.MemberUpdated, handleMemberUpdatedEvent);
            document.removeEventListener(EntityNotificationEventName.ContactUpdated, handleContactUpdatedEvent);
        }
    }, []);

    const requestChangeEmail = React.useCallback(async (proposedEmail: string): Promise<boolean> => {
        if (!coreCloudManager.current) return false;
        const result = await coreCloudManager.current.changeEmail(proposedEmail);
        if (result.isOk) {
            enqueueSnackbar("We've sent a link to your new email address to complete the change. Check your inbox. If you don't receive it immediately check your junk folder.", { variant: "success", autoHideDuration: 10000, });
            return true;
        } else if (!result.isAborted) {
            const errorMessage = ErrorHelper.getErrorMessageForSnackbar("Failed to change email.", result.exception);
            SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, errorMessage, "error");
        }
        return false;
    }, [enqueueSnackbar]);

    return (
        <AuthenticatedContext.Provider
            value={{
                authedMember,
                isLoading,
                requestChangeEmail,
            }}
        >
            {children}
        </AuthenticatedContext.Provider>
    );
}

AuthenticatedProvider.whyDidYouRender = true;
const useAuthenticated = () => React.useContext(AuthenticatedContext);

export { useAuthenticated, AuthenticatedProvider };