import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { SwizzlerProps } from "./types";
import { SvenLinearProgress } from "../SvenLinearProgress";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const Swizzler = React.forwardRef<HTMLDivElement, SwizzlerProps>(({
    text = undefined,
    variant = "circular",
    id,
}: SwizzlerProps, ref) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
    }, []);

    return (
        <Grid container id={id} ref={ref}>
            <Grid item xs={12} style={{ textAlign: "center", }}>
                <Fade
                    in={isLoading}
                    style={{ transitionDelay: isLoading ? "800ms" : "0ms" }}
                    unmountOnExit
                >
                    <Box pt={8} pb={3}>
                        {variant === "circular" ? (
                            <CircularProgress color={theme.palette.type === "dark" ? "secondary" : "primary"} />
                        ) : (
                            <SvenLinearProgress color={theme.palette.type === "dark" ? "secondary" : "primary"} />
                        )}
                        {text &&
                            <Typography style={{ userSelect: "none" }}>
                                {text}
                            </Typography>
                        }
                    </Box>
                </Fade>
            </Grid>
        </Grid>
    );
});

export { Swizzler };