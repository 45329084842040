import { createSvgIcon } from "@material-ui/core/utils";
import * as React from "react";
import { currentTheme } from "../../theme";

export default createSvgIcon(
      <React.Fragment>
            <circle
                  cx="320"
                  cy="320"
                  r="280"
                  style={{ fill: "transparent" }}
            />
            <g style={{ fill: currentTheme.logo(), strokeWidth: 1, stroke: currentTheme.logo() }}>
                  <path d="M259.705,359.451v-72.57l60.476-36.289l60.476,36.289v72.57l-60.476,36.288L259.705,359.451z" />
                  <path d="M115.655,359.451v-72.57l60.476-36.289l60.476,36.289v72.57l-60.476,36.288L115.655,359.451z" />
                  <path d="M187.679,226.485v-72.569l60.477-36.289l60.484,36.289v72.569l-60.484,36.289L187.679,226.485z" />
                  <path d="M331.728,226.485v-72.569l60.476-36.289l60.477,36.289v72.569l-60.477,36.289L331.728,226.485z" />
                  <path d="M403.753,359.451v-72.57l60.477-36.289l60.476,36.289v72.57l-60.476,36.288L403.753,359.451z" />
                  <path d="M331.728,492.424v-72.577l60.476-36.289l60.477,36.289v72.577l-60.477,36.28L331.728,492.424z" />
                  <path d="M187.679,492.424v-72.577l60.477-36.289l60.484,36.289v72.577l-60.484,36.28L187.679,492.424z" />
            </g>
            <g transform="translate(-700,-300),scale(2)" style={{ fill: "currentColor" }}>
                  <path d="M672.919,365.864l19.271-109.284h52.213c24.589,0,43.151,5.769,39.325,27.473
		c-1.98,11.232-10.712,19.428-18.47,23.83c11.303,3.035,15.883,13.205,13.796,25.044c-4.31,24.437-24.479,32.938-52.86,32.938
		H672.919z M716.796,320.178l-3.56,20.187h15.634c8.803,0,15.802-1.819,17.248-10.018c1.687-9.562-6.555-10.169-17.938-10.169
		H716.796z M723.515,282.08l-3.212,18.214h14.873c7.135,0,12.856-3.188,13.981-9.562c1.499-8.5-5.456-8.652-13.045-8.652H723.515z" />
                  <path d="M873.878,365.864h-29.293l2.061-11.688c-9.82,10.927-18.097,13.963-31.149,13.963
		c-17.302,0-26.661-10.624-22.646-33.391l8.538-48.42h31.57l-7.574,42.954c-1.928,10.929,1.25,14.42,8.841,14.42
		c9.106,0,13.193-6.83,15.362-19.122l6.745-38.252h31.57L873.878,365.864z" />
                  <path d="M890.725,365.864l14.026-79.535h31.571l-14.025,79.535H890.725z M910.506,253.696h31.571l-3.961,22.465
		h-31.569L910.506,253.696z" />
                  <path d="M941.268,365.864l19.273-109.284h31.571l-19.273,109.284H941.268z" />
                  <path d="M1047.97,365.864l1.873-10.626c-9.149,9.715-16.543,12.901-26.711,12.901
		c-23.983,0-32.94-18.062-28.977-40.525c4.658-26.41,22.404-43.562,41.377-43.562c10.019,0,16.571,2.429,21.856,10.321l6.666-37.794
		h31.571l-19.273,109.284H1047.97z M1052.727,326.855c1.551-8.803,0.202-18.365-10.271-18.365s-15.123,10.018-16.677,18.821
		c-1.472,8.348-1.059,18.062,9.262,18.062C1045.968,345.373,1051.092,336.115,1052.727,326.855z" />
                  <path d="M1092.291,365.864l19.271-109.284h52.669c31.419,0,44.054,14.421,40.092,36.883
		c-4.148,23.526-20.734,38.402-52.153,38.402h-18.064l-5.994,33.999H1092.291z M1153.478,306.365c8.349,0,15.007-3.34,16.667-12.75
		c1.792-10.169-9.5-11.535-17.091-11.535h-10.168l-4.282,24.285H1153.478z" />
                  <path d="M1215.14,286.329h28.686l-2.836,16.091h0.304c6.598-12.447,15.383-18.367,27.676-18.367
		c1.366,0,2.679,0.305,4.02,0.456l-5.568,31.57c-2.071-0.304-4.241-0.911-6.368-0.911c-12.899,0-20.498,6.071-23.521,23.223
		l-4.847,27.474h-31.569L1215.14,286.329z" />
                  <path d="M1322.725,284.053c28.08,0,42.466,16.545,38.103,41.284c-5.139,29.143-27.278,42.803-52.78,42.803
		c-25.498,0-42.767-13.963-37.976-41.131C1274.944,299.383,1295.859,284.053,1322.725,284.053z M1301.937,325.337
		c-2.649,15.027,1.813,21.553,9.859,21.553c8.499,0,14.812-6.525,17.46-21.553c2.062-11.686-1.547-20.489-9.822-20.035
		C1308.658,305.302,1303.516,316.383,1301.937,325.337z" />
                  <path d="M1415.974,286.329l-12.688,71.945c-3.479,19.733-8.076,34.606-37.522,34.606
		c-4.4,0-8.776-0.152-14.339-0.455l3.962-22.464c12.447,0,14.4-0.759,16.33-11.688l12.687-71.945H1415.974z M1386.195,276.161
		l3.961-22.465h31.571l-3.961,22.465H1386.195z" />
                  <path d="M1451.717,333.229c-1.658,9.412,4.529,15.635,12.876,15.635c5.01,0,9.964-2.275,13.071-6.982h30.204
		c-8.943,19.733-30.129,26.258-48.646,26.258c-25.5,0-42.662-14.57-37.952-41.284c4.606-26.105,27.128-42.803,52.628-42.803
		c29.446,0,43.62,20.339,38.077,49.177H1451.717z M1483.242,317.142c1.287-7.285-4.091-13.812-11.526-13.812
		c-9.412,0-15.662,5.312-17.16,13.812H1483.242z" />
                  <path d="M1543.301,324.275l-19.566-37.946h35.062l6.513,19.885l14.13-19.885h34.604l-33.833,37.796l20.113,41.739
		h-35.215l-7.217-22.769l-15.55,22.769h-34.91L1543.301,324.275z" />
            </g>
      </React.Fragment>
      , "LogoWithText");