let _isDev: boolean = false;
let _isStaging: boolean = false;
let _isLive: boolean = false;

switch (process.env.REACT_APP_MODE?.trim()) {
    case "dev":
        _isDev = true;
        break;

    case "staging":
        _isStaging = true;
        break;

    case "live":
        _isLive = true;
        break;

    default:
        throw new Error(`Unrecognized process.env.REACT_APP_MODE value "${process.env.REACT_APP_MODE}"`);
}

export const isDevEnvironment: boolean = _isDev;
export const isStagingEnvironment: boolean = _isStaging;
export const isLiveEnvironment: boolean = _isLive;
export const apiAppId = process.env.REACT_APP_API_APP_ID!.trim();

/** path from root for current build of mistral */
export const mistralPath = "mistral1.0.44";
export const supportedImageUploadFormats: string = "image/jpeg, image/gif, image/png";

let _acceptWebP: "yes" | "no" | "?" = "?";

export const acceptWebP = async (): Promise<boolean> => {
    // code from https://developers.google.com/speed/webp/faq#in_your_own_javascript
    const supportsWebp = async () => {
        if (!window.createImageBitmap) return false;
        const webpData = "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
        const blob = await fetch(webpData).then(r => r.blob());
        return createImageBitmap(blob).then(() => true, () => false);
    }
    if (_acceptWebP === "?") {
        const b = await supportsWebp();
        _acceptWebP = b ? "yes" : "no";
    }
    if (_acceptWebP === "no") {
        return false;
    } else {
        return true;
    }
}

export class Constants {
    public static readonly companyAddress: string = "Future Space, North Gate (UWE), Filton Road, Stoke Gifford, Bristol, BS34 8RB";
    public static readonly companyName: string = "HBXL Building Software";
    public static readonly companyNumber: string = "3959121";
    public static readonly copyrightDate: string = process.env.REACT_APP_BUILD_YEAR!.trim();
    public static readonly maxDate = new Date(2099, 11, 31);
    public static readonly minDate = new Date(2000, 0, 1);
    public static readonly salesTel: string = "0117 916 7898";
    public static readonly siteTitle: string = process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME.trim() : "";
    public static readonly siteTitleDivider: string = "–";
    public static readonly supportTel: string = "0117 916 7899";
}