export enum FileClassification {
    File = "FILE",
    Folder = "FOLDER",
    Audio = "AUDIO",
    Excel = "EXCEL",
    Archive = "ARCHIVE",
    Text = "TEXT",
    Image = "IMAGE",
    Pdf = "PDF",
    Video = "VIDEO",
    Word = "WORD"
}