import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { SvenIconButton } from "../SvenIconButton";
// import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { DialogIcon } from "./DialogIcon";
import { ResponsiveModalAppBarForFormProps } from "./types";
import * as React from "react";
import { getSubtitle, getTitle } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
        },
        titleContainer: {
            flex: 1,
            marginLeft: theme.spacing(1),
        },
        title: {
            userSelect: "none",
        },
        saveButton: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(0.5),
        },
    }),
);

const ResponsiveModalAppBarForForm = ({
    additionalComponent,
    isSubmitButtonEnabled,
    onClose,
    onSubmitClick,
    helpUrl,
    icon,
    showCloseButton,
    showSubmitButton,
    submitButtonText,
    title,
}: ResponsiveModalAppBarForFormProps) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position="relative">
                <Toolbar>
                    {showCloseButton &&
                        <SvenIconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            text="Close"
                            aria-label="close"
                        >
                            <CloseRoundedIcon />
                        </SvenIconButton>
                    }
                    {icon &&
                        <DialogIcon icon={icon} />
                    }
                    <div className={classes.titleContainer}>
                        <Typography
                            variant="h6"
                            className={classes.title}
                            noWrap
                        >
                            {getTitle(title)}
                        </Typography>
                        {getSubtitle(title) &&
                            <Typography
                                variant="caption"
                                className={classes.title}
                                noWrap
                            >
                                {getSubtitle(title)}
                            </Typography>
                        }
                    </div>
                    {additionalComponent}
                    {showSubmitButton &&
                        <Button
                            autoFocus
                            onClick={onSubmitClick}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={!isSubmitButtonEnabled}
                            className={classes.saveButton}
                        >
                            {submitButtonText}
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            {/* {helpUrl &&
                <SvenIconButton
                    color="secondary"
                    text="Help"
                    showText={true}
                    style={{ alignSelf: "flex-end" }}
                    flexDirection="row-reverse"
                    onClick={() => {
                        const link = document.createElement("a");
                        link.href = helpUrl;
                        link.setAttribute("target", "_blank");
                        link.setAttribute("rel", "noopener");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                >
                    <HelpOutlineRoundedIcon />
                </SvenIconButton>
            } */}
        </React.Fragment>
    );
}

export { ResponsiveModalAppBarForForm };