import * as React from "react";
import { SessionContext, defaultData } from "./SessionContext";
import { SessionProviderProps, SessionStatusChangeMessage } from "./types";
import { useSnackbar } from "notistack";
import { SnackbarHelper } from "../../helpers/SnackbarHelper";
import { VerbosityLevel } from "../../types";
import { UrlHelper } from "../../helpers/UrlHelper";
import { useHistory } from "react-router-dom";
import { SessionManager } from "./SessionManager";
import { JsonHelper } from "../../helpers/JsonHelper";

const maybeLogMessage = (message: string, ...args: any[]) => {
    // if (!isLiveEnvironment) {
    //const css = `display: inline-block; padding: 4px 8px; margin: 4px 0; border-radius: 2px; background-color: ${lightGreen[400]}; color: #000000; border-left: 8px solid rgba(0, 0, 0, 0.5);`;
    //console.log(`%c💼 ${message} [${new Date().toLocaleString()}]`, css, ...args);
    // }
}

const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [destination, setDestination] = React.useState<string | null>(() => window.location.pathname + window.location.hash + window.location.search);
    const [data, setData] = React.useState(() => defaultData);
    const [sessionManager] = React.useState<SessionManager>(() => new SessionManager());
    /** wire up event handlers to receive messages from SessionManager */
    React.useEffect(() => {
        function handleStatusChange(event: MessageEvent) {
            if (event.data.source && event.data.source === "SessionStatusChangeMessage") {
                const myMessage: SessionStatusChangeMessage = event.data;
                maybeLogMessage("handleStatusChange", JsonHelper.clone(myMessage.payload));
                setData(previous => {
                    if (previous.status === myMessage.payload.status && previous.workspaceId === myMessage.payload.workspaceId) {
                        return previous;
                    } else if (myMessage.payload.status === defaultData.status && myMessage.payload.workspaceId === defaultData.workspaceId) {
                        return defaultData;
                    } else {
                        return { status: myMessage.payload.status, workspaceId: myMessage.payload.workspaceId };
                    }
                });
            }

        }
        maybeLogMessage("SessionProvider - mounted");
        window.addEventListener("message", handleStatusChange);

        return () => {
            maybeLogMessage("SessionProvider - unmounted");
            window.removeEventListener("message", handleStatusChange);
        }
    }, []);

    React.useEffect(() => {
        maybeLogMessage("SessionProvider data mutated", data);
    }, [data]);

    React.useEffect(() => {
        return () => {
            sessionManager.destructor();
        }
    }, [sessionManager]);

    const changePassword = React.useCallback(async (oldPassword: string, newPassword: string): Promise<boolean> => {
        const result = await sessionManager.changePassword(oldPassword, newPassword);
        if (result.success) {
            enqueueSnackbar("Password successfully changed. You will need to log in again on other devices.", { variant: "success", autoHideDuration: 10000, });
            return true;
        } else {
            SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, result.errorMessage, "error");
        }
        return false;
    }, [sessionManager, enqueueSnackbar]);

    const signOut = React.useCallback(async (): Promise<boolean> => {
        const result = await sessionManager.signOut();
        if (result.success) {
            window.location.replace("/");
            return true;
        } else {
            SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, result.errorMessage, "error");
            return false;
        }
    }, [sessionManager, enqueueSnackbar]);

    const signIn = React.useCallback(async (email: string, password: string, persist: boolean, dest: string | null | undefined): Promise<boolean> => {
        const result = await sessionManager.signIn(email, password, persist);
        if (result.success) {
            if (dest) {
                if (dest === "/") {
                    setDestination(UrlHelper.homeUrl);
                }
                else if (dest !== UrlHelper.loginUrl) {
                    setDestination(dest);
                }
            }
            return true;
        } else {
            SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, result.errorMessage, "error");
        }
        return false;
    }, [sessionManager, enqueueSnackbar]);

    const changeWorkspace = React.useCallback(async (workspaceId: string): Promise<boolean> => {
        const result = await sessionManager.changeWorkspace(workspaceId);
        if (result.success) {
            setDestination(null);
            history.push(UrlHelper.homeUrl);
            return true
        } else {
            SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, result.errorMessage, "error");
        }
        return false;
    }, [sessionManager, enqueueSnackbar, history]);

    const getValidAccessToken = React.useCallback(async (): Promise<string> => {
        return await sessionManager.getValidAccessToken();
    }, [sessionManager]);

    const trySilentSignOut = React.useCallback(async () => await sessionManager.trySilentSignOut(), [sessionManager]);

    return (
        <SessionContext.Provider
            value={{
                changePassword,
                changeWorkspace,
                data,
                destination,
                getValidAccessToken,
                trySilentSignOut,
                signIn,
                signOut,
            }}
        >
            {children}
        </SessionContext.Provider>

    );
};

SessionProvider.whyDidYouRender = true;
export { SessionProvider };
