import { isLiveEnvironment } from "../../Globals";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { EntityNotificationEventName, ResponseResult, SvenException } from "../ApiClient/Schema";

export class BaseCloudManager {

    protected maybeLogMessage = (logInLive: boolean, message: string, color: string, backgroundColor: string, args?: any) => {
        if (isLiveEnvironment && !logInLive) return;
        const css = `display: inline-block; padding: 4px 8px; margin: 4px 0; border-radius: 2px; background-color: ${backgroundColor}; color: ${color};border-left:8px solid rgba(0,0,0,0.5);`;
        if (args) {
            console.log(`%c🚧${message} [${new Date().toLocaleString()}]`, css, args);
        } else {
            console.log(`%c🚧${message} [${new Date().toLocaleString()}]`, css);
        }
    }

    protected handleSvenException = <T>(ex: SvenException | undefined, methodName: string): ResponseResult<T> => {
        return {
            isOk: false,
            isAborted: false,
            exception: ex,
        }
    }

    protected handleError = <T>(ex: any, methodName: string): ResponseResult<T> => {
        if (ex.name === "AbortError") {
            // if (process.env.NODE_ENV !== "production") {
            //     this.maybeLogMessage(false, `✖ ${methodName} aborted. This message will not appear in production builds.`, "#FFFFFF", "#ef6c00");
            // }
            return {
                isOk: false,
                isAborted: true,
            };
        } else {
            const response = {
                isOk: false,
                isAborted: false,
                exception: ErrorHelper.toSvenException(ex),
            };
            return response;
        }
    }

    protected handleSuccess = <T>(response: T): ResponseResult<T> => {
        return {
            isOk: true,
            response: response,
            isAborted: false,
        }
    }

    protected handleSuccessWithEvent = <T>(response: T, eventName: EntityNotificationEventName): ResponseResult<T> => {
        document.dispatchEvent(new CustomEvent(eventName, { detail: response }));

        return {
            isOk: true,
            response: response,
            isAborted: false,
        }
    }
}