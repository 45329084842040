import { useTheme } from "@material-ui/core/styles";
import * as React from "react";
import { ResponsiveModalDialogWithForm } from "../ResponsiveModalDialog";
import { MessageBoxProps } from "./types";

const MessageBox = ({
    allowDesktopFullScreen = false,
    allowPhoneFullScreen = false,
    fullWidth = false,
    showCloseButton = false,
    autofocusSubmitButton = true,
    submit,
    transition = "fade",
    showSubmitButton,
    ...rest
}: MessageBoxProps) => {
    const theme = useTheme();
    const handleSubmit = async () => submit(true);

    return (
        <ResponsiveModalDialogWithForm
            {...rest}
            autofocusSubmitButton={autofocusSubmitButton}
            allowDesktopFullScreen={allowDesktopFullScreen}
            allowPhoneFullScreen={allowPhoneFullScreen}
            doNotCloseOnBackdropClick={true}
            fullWidth={fullWidth}
            showSubmitButton={showSubmitButton}
            showCloseButton={showCloseButton}
            onSubmit={handleSubmit}
            transition={transition}
            transitionDuration={{ enter: theme.transitions.duration.enteringScreen, exit: 50 }}
            zIndex={theme.zIndex.tooltip}
        >
            <React.Fragment />
        </ResponsiveModalDialogWithForm>
    );
}

export { MessageBox };