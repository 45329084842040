import { ApiException } from "../services/ApiClient/CoreApiClient";
import { HttpValidationProblemDetails, ProblemDetails, SvenException } from "../services/ApiClient/Schema";

export class ErrorHelper {

    public static readonly failedToFetchStatus: number = -1;
    public static getErrorMessageForSnackbar = (prefix: string, ex: SvenException | undefined): string => {
        if (!ex) return prefix;
        let s = prefix + " ";
        if (ex.title && ex.message) {
            s += ` ${ex.title}: ${ex.message}`
        } else if (ex.title) {
            s += ` ${ex.title}`;
        } else if (ex.message) {
            s += ` ${ex.message}`;
        }
        return s.trim();
    }
    /**
     *
     * @param ex
     */
    public static toSvenException = (ex: unknown): SvenException => {
        let title = this.errorTitleFromException(ex);
        const message = this.errorMessageFromException(ex);
        const status = this.errorStatusFromException(ex)
        const stack = this.errorStackFromException(ex)
        if (!title) {
            title = this.errorTitleFromStatus(status ? status : 0);
        }
        const result: SvenException = {
            title: title,
            message: message,
            status: status ? status : 0,
            stack: stack,
        };
        return result;
    }

    private static errorTitleFromStatus = (status: number): string => {
        // taken from https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
        switch (status) {
            // 4XX
            case 400: return "Bad Request";
            case 401: return "Unauthorized";
            case 402: return "Payment Required";
            case 403: return "Forbidden";
            case 404: return "Not Found";
            case 405: return "Method Not Allowed";
            case 406: return "Not Acceptable";
            case 407: return "Proxy Authentication Required";
            case 408: return "Request Timeout";
            case 409: return "Conflict";
            case 410: return "Gone";
            case 411: return "Length Required";
            case 412: return "Precondition Failed";
            case 413: return "Payload Too Large";
            case 414: return "URI Too Long";
            case 415: return "Unsupported Media Type";
            case 416: return "Range Not Satisfiable";
            case 417: return "Expectation Failed";
            case 418: return "I'm a teapot";
            case 421: return "Misdirected Request";
            case 425: return "Too Early";
            case 426: return "Upgrade Required";
            case 428: return "Precondition Required";
            case 429: return "Too Many Requests";
            case 431: return "Request Header Fields Too Large";
            case 451: return "Unavailable For Legal Reasons";
            // 5XX
            case 500: return "Internal Server Error";
            case 501: return "Not Implemented";
            case 502: return "Bad Gateway";
            case 503: return "Service Unavailable";
            case 504: return "Gateway Timeout";
            case 505: return "HTTP Version Not Supported";
            case 506: return "Variant Also Negotiates";
            case 510: return "Not Extended";
            case 511: return "Network Authentication Required";

            default: return "Error";
        }
    }

    public static errorStackFromException = (ex: unknown): string | undefined => {
        if (!ex) return undefined;
        if (ex instanceof Error) {
            return (ex as Error).stack;
        }
        return undefined;
    }

    public static errorStatusFromException = (ex: unknown): number | undefined => {
        if (!ex) return undefined;
        if (ApiException.isApiException(ex)) {
            return ex.status;
        }

        if ((ex as ProblemDetails).status) {
            return (ex as ProblemDetails).status;
        }

        if (ex instanceof TypeError) {
            return this.failedToFetchStatus;
        }
        return undefined;
    }

    public static errorTitleFromException = (ex: unknown): string | undefined => {
        if (!ex) return undefined;

        if (ApiException.isApiException(ex)) {
            return ex.message;
        }
        if ((ex as ProblemDetails).title) {
            return (ex as ProblemDetails).title;
        }

        if (ex instanceof TypeError) {
            return "Failed to fetch";
        }

        if (ex instanceof Error) {
            return (ex as Error).name;
        }

        return undefined;
    }

    public static errorMessageFromException = (ex: unknown): string | undefined => {
        if (!ex) return undefined;

        if (ApiException.isApiException(ex)) {
            return ex.response;
        }

        if ((ex as HttpValidationProblemDetails).detail || (ex as HttpValidationProblemDetails).errors) {
            let s = "";
            if ((ex as HttpValidationProblemDetails).detail) s += `${(ex as HttpValidationProblemDetails).detail}\n`;

            const err = (ex as HttpValidationProblemDetails).errors;
            if (err) {
                for (let key in err) {
                    let value = err[key];
                    s += `\n - ${key}: ${value}\n`;
                }
            }

            return s;
        }

        if (ex instanceof TypeError) {
            return "A network error was encountered";
        }

        if (ex instanceof Error) {
            // a generic error
            return (ex as Error).name + " - " + (ex as Error).message;
        }

        if (typeof ex === "string") {
            return ex;
        }

        const key = Object.keys(ex).find(x => x === "message");
        if (key) {
            return Reflect.get(ex, key);
        }

        if (typeof ex === "object") {
            return JSON.stringify(ex, null, 2);
        }
        return undefined;
    }
}