import enAU from "date-fns/locale/en-AU";
import enCA from "date-fns/locale/en-CA";
import enGB from "date-fns/locale/en-GB";
import enIE from "date-fns/locale/en-IE";
import enNZ from "date-fns/locale/en-NZ";
import enUS from "date-fns/locale/en-US";
import enZA from "date-fns/locale/en-ZA";
import * as React from "react";
import { CultureInfoResponseExt, DateFormat, ILocalizationContext, LocaleInfo, LocalizationProviderProps } from "./types";
import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import isYesterday from "date-fns/isYesterday";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import add from "date-fns/add";
import sub from "date-fns/sub";
import { NoProviderError } from "../../types";

const defaultLocale: LocaleInfo = {
    dateLocale: enGB,
    localeString: "en-GB",
    localeFolder: "en-GB",
    ianaTimeZone: "Europe/London",
    deviceIanaTimeZone: "Europe/London",
    datePickerFormat: "dd/MM/yyyy",
};

const LocalizationContext = React.createContext<ILocalizationContext>({
    setLocale: () => { throw new NoProviderError() },
    getDeviceCultureInfoOrFallback: () => { throw new NoProviderError() },
    getRelativeDateName: () => { throw new NoProviderError() },
    currentLocale: defaultLocale,
    formatDate: () => { throw new NoProviderError() },
    getOutgoingAdjustedDate: () => { throw new NoProviderError() },
    getTimeZoneOffset: () => { throw new NoProviderError() },
    getIncomingAdjustedDate: () => { throw new NoProviderError() },
    cultureInfos: [],
    getCultureInfo: () => { throw new NoProviderError() },
});

const LocalizationProvider: React.FC<LocalizationProviderProps> = ({ children }) => {

    const [currentLocale, setCurrentLocale] = React.useState<LocaleInfo>(defaultLocale);
    const [cultureInfos, setCultureInfos] = React.useState<CultureInfoResponseExt[]>([]);

    React.useEffect(() => {
        const asyncFunction = async () => {
            try {
                // CDN will have cached regions.json so increment number to get fresh version
                const response = await fetch("/locale/regions.json?v=1", { signal: abortController.signal });
                const data: CultureInfoResponseExt[] = await response.json();
                setCultureInfos(data.filter(x => x.published));
            }
            catch (ex: any) {
                //console.error("CultureInfoSelect error:", ex);
            }
        }
        const abortController = new AbortController();
        asyncFunction();
        return () => {
            abortController.abort();
        }
    }, []);


    const getDeviceCultureInfoOrFallback = React.useCallback((): CultureInfoResponseExt => {
        const id = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
        const deviceCultureInfo = cultureInfos.find(x => x.id === id);
        if (deviceCultureInfo) return deviceCultureInfo;
        return cultureInfos.find(x => x.id === defaultLocale.localeString)!;
    }, [cultureInfos]);

    const getCultureInfo = React.useCallback((id: string): CultureInfoResponseExt | undefined => cultureInfos.find(x => x.id === id), [cultureInfos]);

    const getTimeZoneOffset = React.useCallback((date: Date): number => {
        const workingTimeZoneOffset = getTimezoneOffset(currentLocale.ianaTimeZone, date);
        const deviceTimeZoneOffset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, date);
        const milliseconds = deviceTimeZoneOffset - workingTimeZoneOffset;
        return milliseconds;
    }, [currentLocale]);

    const getIncomingAdjustedDate = React.useCallback((date: Date): Date => {
        const milliseconds = getTimeZoneOffset(date);
        return sub(date, { seconds: milliseconds / 1000 })
    }, [getTimeZoneOffset]);

    const getOutgoingAdjustedDate = React.useCallback((date: Date): Date => {
        const milliseconds = getTimeZoneOffset(date);
        return add(date, { seconds: milliseconds / 1000 })
    }, [getTimeZoneOffset]);

    const setLocale = React.useCallback(async (localeString: string, timeZone: string) => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let localeFolder = "en-GB";
        let datePickerFormat = "dd/MM/yyyy";
        let dateLocale = enGB;
        let dateFnsLocaleName = "en-GB";

        const cultureInfo = getCultureInfo(localeString);
        if (cultureInfo) {
            if (cultureInfo.languageFolder) {
                localeFolder = cultureInfo.languageFolder;
            }

            if (cultureInfo.datePickerFormat) {
                datePickerFormat = cultureInfo.datePickerFormat;
            }

            if (cultureInfo.dateFolder && cultureInfo.dateFolder !== dateFnsLocaleName) {
                // dateLocale = await import(
                //     /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
                //     `date-fns/locale/${cultureInfo.dateFolder}/index.js`
                // );
                switch (cultureInfo.dateFolder) {
                    case "en-GB":
                        dateLocale = enGB;
                        break;

                    case "en-AU":
                        dateLocale = enAU;
                        break;

                    case "en-CA":
                        dateLocale = enCA;
                        break;

                    case "en-IE":
                        dateLocale = enIE;
                        break;

                    case "en-NZ":
                        dateLocale = enNZ;
                        break;

                    case "en-ZA":
                        dateLocale = enZA;
                        break;

                    case "en-US":
                        dateLocale = enUS;
                        break;
                }
            }
        }

        setCurrentLocale({
            dateLocale: dateLocale,
            localeString: localeString,
            localeFolder: localeFolder,
            ianaTimeZone: timeZone,
            datePickerFormat: datePickerFormat,
            deviceIanaTimeZone: tz,
        });

    }, [getCultureInfo]);


    const formatDate = React.useCallback((date: number | Date, dateformat: DateFormat, ignoreTimeZone: boolean | undefined = undefined): string => {
        const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let timezoneFormat = "";

        // if api defined timezone and local device timezone differ the check
        // to see if timezone inf needs appending to the format string
        if (!ignoreTimeZone && currentLocale.ianaTimeZone !== deviceTimeZone) {
            // 'p', 'pp, 'ppp' are the time time elements of a time or date and time format
            if (dateformat.indexOf("p") > -1) {
                timezoneFormat = " zzz";
            }
        }
        if (ignoreTimeZone) {
            return format(date, dateformat, { locale: currentLocale.dateLocale })

        } else {
            return formatInTimeZone(date, currentLocale.ianaTimeZone, dateformat + timezoneFormat, { locale: currentLocale.dateLocale })
        }
    }, [currentLocale]);

    const getRelativeDateName = React.useCallback((date: Date): string => {
        if (isToday(date)) return "Today";
        if (isTomorrow(date)) return "Tomorrow";
        if (isYesterday(date)) return "Yesterday"
        const daysAgo = differenceInDays(new Date(), date);
        // eeee = Full day name e.g. Monday, Tuesday..
        if (daysAgo < 7) return formatDate(date, DateFormat.LongDay);
        // PPP = Long localized date e.g. 7 October 2021...
        return formatDate(date, DateFormat.MediumDate);
    }, [formatDate]);



    return (
        <LocalizationContext.Provider
            value={{
                cultureInfos,
                currentLocale,
                formatDate,
                getCultureInfo,
                getIncomingAdjustedDate,
                getDeviceCultureInfoOrFallback,
                getOutgoingAdjustedDate,
                getRelativeDateName,
                getTimeZoneOffset,
                setLocale,
            }}
        >
            {children}
        </LocalizationContext.Provider>
    );
}
LocalizationProvider.whyDidYouRender = true;

const useLocalization = () => React.useContext(LocalizationContext);

export { useLocalization, LocalizationProvider };