import * as React from "react";
import { Helmet } from "react-helmet";
import { Constants } from "../../Globals";
import { IPageTitleContext, Title } from "./types";
import { NoProviderError } from "../../types";

const headTitle = (page: string | undefined): string => {
    if (page) {
        return `${page} ${Constants.siteTitleDivider} ${Constants.siteTitle}`;
    }
    return Constants.siteTitle;
}

const PageTitleContext = React.createContext<IPageTitleContext>({
    title: { title: undefined },
    setTitle: () => { throw new NoProviderError() },
});

interface PageTitleProviderProps {
    children?: React.ReactNode
};

const PageTitleProvider: React.FC<PageTitleProviderProps> = ({ children }) => {
    const [title, setTitleInternal] = React.useState<Title>({ title: undefined });

    const setTitle = React.useCallback((value: Title) => {
        setTitleInternal(previous => {
            if (previous.h1 === value.h1 && previous.title === value.title)
                return previous;

            return value;

        });
    }, []);

    return (
        <PageTitleContext.Provider value={{
            setTitle,
            title,
        }}>
            <React.Fragment>
                <Helmet defer={false}>
                    <title>{headTitle(title.title)}</title>
                </Helmet>
                {children}
            </React.Fragment>
        </PageTitleContext.Provider>
    );
}

/** React hook for screen title */
const usePageTitle = () => React.useContext(PageTitleContext);

export { usePageTitle, PageTitleProvider };