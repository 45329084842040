import * as React from "react";
import { DensityProviderProps, DensitySetting, IDensityContext } from "./types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";



const spacious: DensitySetting = { density: "spacious", gridSpacing: 3, };
const compact: DensitySetting = { density: "compact", gridSpacing: 2, }

const DensityContext = React.createContext<IDensityContext>({
    densitySetting: spacious,
});

const DensityProvider: React.FC<DensityProviderProps> = ({ children }) => {
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const [densitySetting, setDensitySetting] = React.useState<DensitySetting>(spacious);

    React.useEffect(() => {
        setDensitySetting(smDown ? spacious : compact);
    }, [smDown]);

    return (
        <DensityContext.Provider value={{ densitySetting }}>
            {children}
        </DensityContext.Provider>
    );
}

const useDensity = () => React.useContext(DensityContext);

export { useDensity, DensityProvider };