import * as React from "react";
import { SvenIconButton } from "../SvenIconButton";
import { BackButtonProps } from "./types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ThemeHelper } from "../../../theme";

/** Back button component that will display an os dependent back arrow */
const BackButton = ({
    ...rest
}: BackButtonProps) => {
    const [icon, setIcon] = React.useState<JSX.Element>(<ArrowBackIcon />);

    React.useEffect(() => {
        if (ThemeHelper.isApple()) setIcon(<ArrowBackIosIcon />);
    }, []);

    return (
        <SvenIconButton {...rest}>
            {icon}
        </SvenIconButton>
    );
}

export { BackButton }