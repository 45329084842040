import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
// import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import * as React from "react";
import { CloseFullscreenRoundedIcon, OpenInFullRoundedIcon } from "../../icons";
import { SvenIconButton } from "../SvenIconButton";
import { DialogIcon } from "./DialogIcon";
import { DesktopDialogTitleProps } from "./types";
import Box from "@material-ui/core/Box";
import { getSubtitle, getTitle } from "./utils";

const DesktopDialogTitle = ({
    additionalComponent,
    allowDesktopFullScreen,
    desktopFullScreen,
    helpUrl,
    icon,
    movable,
    onClose,
    onEnterFullScreen,
    onExitFullScreen,
    showCloseButton,
    title,
}: DesktopDialogTitleProps) => {
    const theme = useTheme();

    const FullScreenButtons = () => {
        if (!allowDesktopFullScreen) return <React.Fragment />;
        if (desktopFullScreen) {
            return (
                <SvenIconButton
                    color="inherit"
                    onClick={onExitFullScreen}
                    text="Exit full screen"
                >
                    <CloseFullscreenRoundedIcon />
                </SvenIconButton>
            );
        }
        return (
            <SvenIconButton
                color="inherit"
                onClick={onEnterFullScreen}
                text="Full screen"
            >
                <OpenInFullRoundedIcon />
            </SvenIconButton>
        );
    }

    return (
        <React.Fragment>
            <DialogTitle
                disableTypography
                className="handle"
                style={{
                    cursor: movable && !desktopFullScreen ? "move" : "auto",
                    display: "flex",
                    flexDirection: "column",
                    //color: theme.palette.primary.contrastText,
                    //backgroundColor: theme.palette.primary.light,
                    //paddingTop: theme.spacing(1),
                    //paddingBottom: theme.spacing(1),
                    //marginBottom: theme.spacing(1),
                    //borderBottom: `1px solid ${theme.palette.primary.dark}`,
                }}
            >
                <Box display="flex" flexDirection="row" width="100%" alignItems="center" position="relative">
                    {icon &&
                        <DialogIcon icon={icon} style={{ marginRight: theme.spacing(1) }} />
                    }
                    <Typography
                        variant="h6"
                        style={{
                            flexGrow: 1,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            userSelect: "none",
                        }}
                    >
                        {getTitle(title)}
                    </Typography>
                    {additionalComponent}
                    {/* {helpUrl && 
                    <SvenIconButton
                        color="inherit"
                        text="Help"
                        onClick={() => {
                            const link = document.createElement("a");
                            link.href = helpUrl;
                            link.setAttribute("target", "_blank");
                            link.setAttribute("rel", "noopener");
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}
                    >
                        <HelpOutlineRoundedIcon />
                    </SvenIconButton>
                } */}
                    {FullScreenButtons()}
                    {showCloseButton &&
                        <SvenIconButton
                            edge="end"
                            color="inherit"
                            onClick={onClose}
                            text="Close"
                        >
                            <CloseRoundedIcon />
                        </SvenIconButton>
                    }
                </Box>
                {getSubtitle(title) &&
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        style={{
                            fontSize: "larger",
                            flexGrow: 1,
                            top: 52,
                            position: "absolute",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            userSelect: "none",
                        }}
                    >
                        {getSubtitle(title)}
                    </Typography>
                }
            </DialogTitle>
        </React.Fragment>
    );
}

export { DesktopDialogTitle };