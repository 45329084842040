import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Swizzler } from "../Swizzler";
import { SvenBackdropProps } from "./types";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const SvenBackdrop = ({
    isBusy,
    delayDuration = 500,
    swizzlerProps,
    style,
}: SvenBackdropProps) => {
    const classes = useStyles();

    return (
        <Backdrop
            unmountOnExit
            open={isBusy}
            classes={{ root: classes.root }}
            style={{ ...style, transitionDelay: isBusy ? `${delayDuration}ms` : "0ms" }}
        >
            <Swizzler {...swizzlerProps} />
        </Backdrop>
    );
}

export { SvenBackdrop };