import * as React from "react";
import { MessageBox } from "../../components/core/MessageBox";
import { MessageBoxOptions } from "./types";

const MessageBoxContext = React.createContext<(options: MessageBoxOptions) => Promise<boolean>>(Promise.reject);

type Props = {
    children?: React.ReactNode
};

const MessageBoxProvider: React.FC<Props> = ({ children }) => {
    const [optionsState, setOptionsState] = React.useState<MessageBoxOptions | undefined>(undefined);

    const awaitingPromiseRef = React.useRef<{
        resolve: (s: boolean) => void;
        reject: () => void;
    }>();

    const openDialog = React.useCallback((options: MessageBoxOptions) => {
        setOptionsState(options);
        return new Promise<boolean>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    }, []);

    const handleClose = React.useCallback(() => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(false);
        }
        setOptionsState(undefined);
    }, []);

    const handleSubmit = React.useCallback(() => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(true);
        }
        setOptionsState(undefined);
    }, []);

    return (
        <React.Fragment>
            <MessageBoxContext.Provider value={openDialog}>
                {children}
            </MessageBoxContext.Provider>
            <MessageBox
                cancelButtonText={optionsState?.cancelButtonText}
                caption={optionsState?.caption}
                close={handleClose}
                maxWidth={optionsState?.maxWidth ?? "sm"}
                icon={optionsState?.icon}
                captionStyle={optionsState?.style}
                isOpen={Boolean(optionsState)}
                submit={handleSubmit}
                submitButtonText={optionsState?.submitButtonText}
                title={optionsState?.title}
                {...optionsState}
            />
        </React.Fragment>
    );
};

const useMessageBox = () => React.useContext(MessageBoxContext)

export { useMessageBox, MessageBoxProvider };