import * as React from "react"
export type Orientation = "landscape" | "portrait" | "square";

export interface DeviceMeasurements {
    orientation: Orientation;
    /** longest side / shortest side */
    // aspectRatio: number;
    /** are the dimensions of the viewport that of a phone sized device */
    maybePhone: boolean;
    width: number;
    height: number;
}

// https://blisk.io/devices
// iphone pro max = 430
// galaxy note 5 = 480
export const maxPhoneWidth = 480

const getInfo = (): DeviceMeasurements => {
    if (window.innerWidth === window.innerHeight) {
        return ({
            orientation: "square",
            // aspectRatio: 1,
            maybePhone: Math.min(window.innerHeight, window.innerWidth) <= maxPhoneWidth,
            width: window.innerWidth,
            height: window.innerHeight,
        });
    } else {
        return ({
            orientation: window.innerWidth > window.innerHeight ? "landscape" : "portrait",
            // aspectRatio: Math.max(window.innerHeight, window.innerWidth) / Math.min(window.innerHeight, window.innerWidth),
            maybePhone: Math.min(window.innerHeight, window.innerWidth) <= maxPhoneWidth,
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
}

export const useDeviceMeasurements = () => {
    // track if the component is still mounted
    const isMountedRef = React.useRef(true);
    React.useEffect(() => () => { isMountedRef.current = false }, []);

    const [deviceMeasurements, setDeviceMeasurements] = React.useState<DeviceMeasurements>(getInfo());

    const timeoutId = React.useRef(0);

    React.useEffect(() => {
        const processDeviceMeasurements = () => {
            const newInfo = getInfo();
            if (isMountedRef.current) {
                setDeviceMeasurements(previous => {
                    if (//previous.aspectRatio === newInfo.aspectRatio &&
                        previous.height === newInfo.height &&
                        previous.maybePhone === newInfo.maybePhone &&
                        previous.orientation === newInfo.orientation &&
                        previous.width === newInfo.width) {
                        return previous;
                    } else {
                        return newInfo;
                    }
                });
            }
        }

        // Debouncing window.onresize will only call the event handler after the
        // event has stopped firing for a certain amount of time.This will ensure
        // that your function will only be called once the resizing is "complete."
        // https://web.archive.org/web/20220714020647/https://bencentra.com/code/2015/02/27/optimizing-window-resize.html
        function handleResize(e: UIEvent) {
            window.clearTimeout(timeoutId.current);
            timeoutId.current = window.setTimeout(processDeviceMeasurements, 250);
        }
        processDeviceMeasurements();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return deviceMeasurements;
}