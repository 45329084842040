import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from "@material-ui/core/colors";
import { ColorPaletteDefinition } from "./types";



export const redPalette: ColorPaletteDefinition = {
    hue: "Red",
    colors: [
        //{name:"100",value: red[100]},
        //{name:"200",value: red[200]},
        { name: "300", value: red[300] },
        { name: "400", value: red[400] },
        { name: "500", value: red[500] },
        { name: "600", value: red[600] },
        { name: "700", value: red[700] },
        { name: "800", value: red[800] },
        { name: "900", value: red[900] },
    ],
    accents: [
        { name: "A200", value: red["A200"] },
        { name: "A400", value: red["A400"] },
        { name: "A700", value: red["A700"] },
    ],
};
export const pinkPalette: ColorPaletteDefinition = {
    hue: "Pink",
    colors: [
        //{name:"100",value:pink[100]},
        //{name:"200",value:pink[200]},
        { name: "300", value: pink[300] },
        { name: "400", value: pink[400] },
        { name: "500", value: pink[500] },
        { name: "600", value: pink[600] },
        { name: "700", value: pink[700] },
        { name: "800", value: pink[800] },
        { name: "900", value: pink[900] },
    ],
    accents: [
        { name: "A200", value: pink["A200"] },
        { name: "A400", value: pink["A400"] },
        { name: "A700", value: pink["A700"] },
    ],
};
export const purplePalette: ColorPaletteDefinition = {
    hue: "Purple",
    colors: [
        //{name:"100",value:purple[100]},
        //{name:"200",value:purple[200]},
        { name: "300", value: purple[300] },
        { name: "400", value: purple[400] },
        { name: "500", value: purple[500] },
        { name: "600", value: purple[600] },
        { name: "700", value: purple[700] },
        { name: "800", value: purple[800] },
        { name: "900", value: purple[900] },
    ],
    accents: [
        { name: "A200", value: purple["A200"] },
        { name: "A400", value: purple["A400"] },
        { name: "A700", value: purple["A700"] },
    ],
};
export const deepPurplePalette: ColorPaletteDefinition = {
    hue: "Deep purple",
    colors: [
        //{name:"100",value:deepPurple[100]},
        //{name:"200",value:deepPurple[200]},
        { name: "300", value: deepPurple[300] },
        { name: "400", value: deepPurple[400] },
        { name: "500", value: deepPurple[500] },
        { name: "600", value: deepPurple[600] },
        { name: "700", value: deepPurple[700] },
        { name: "800", value: deepPurple[800] },
        { name: "900", value: deepPurple[900] },
    ],
    accents: [
        { name: "A200", value: deepPurple["A200"] },
        { name: "A400", value: deepPurple["A400"] },
        { name: "A700", value: deepPurple["A700"] },
    ],
};
export const indigoPalette: ColorPaletteDefinition = {
    hue: "Indigo",
    colors: [
        //{name:"200",value:indigo[100]},
        //{name:"200",value:indigo[200]},
        { name: "300", value: indigo[300] },
        { name: "400", value: indigo[400] },
        { name: "500", value: indigo[500] },
        { name: "600", value: indigo[600] },
        { name: "700", value: indigo[700] },
        { name: "800", value: indigo[800] },
        { name: "900", value: indigo[900] },
    ],
    accents: [
        { name: "A200", value: indigo["A200"] },
        { name: "A400", value: indigo["A400"] },
        { name: "A700", value: indigo["A700"] },
    ],
};
export const bluePalette: ColorPaletteDefinition = {
    hue: "Blue",
    colors: [
        //{name:"100",value:blue[100]},
        //{name:"200",value:blue[200]},
        { name: "300", value: blue[300] },
        { name: "400", value: blue[400] },
        { name: "500", value: blue[500] },
        { name: "600", value: blue[600] },
        { name: "700", value: blue[700] },
        { name: "800", value: blue[800] },
        { name: "900", value: blue[900] },
    ],
    accents: [
        { name: "A200", value: blue["A200"] },
        { name: "A400", value: blue["A400"] },
        { name: "A700", value: blue["A700"] },
    ],
};
export const lightBluePalette: ColorPaletteDefinition = {
    hue: "Light blue",
    colors: [
        //{name:"100",value:lightBlue[100]},
        //{name:"200",value:lightBlue[200]},
        { name: "300", value: lightBlue[300] },
        { name: "400", value: lightBlue[400] },
        { name: "500", value: lightBlue[500] },
        { name: "600", value: lightBlue[600] },
        { name: "700", value: lightBlue[700] },
        { name: "800", value: lightBlue[800] },
        { name: "900", value: lightBlue[900] },
    ],
    accents: [
        { name: "A200", value: lightBlue["A200"] },
        { name: "A400", value: lightBlue["A400"] },
        { name: "A700", value: lightBlue["A700"] },
    ],
};
export const cyanPalette: ColorPaletteDefinition = {
    hue: "Cyan",
    colors: [
        //{name:"100",value:cyan[100]},
        //{name:"200",value:cyan[200]},
        { name: "300", value: cyan[300] },
        { name: "400", value: cyan[400] },
        { name: "500", value: cyan[500] },
        { name: "600", value: cyan[600] },
        { name: "700", value: cyan[700] },
        { name: "800", value: cyan[800] },
        { name: "900", value: cyan[900] },
    ],
    accents: [
        { name: "A200", value: cyan["A200"] },
        { name: "A400", value: cyan["A400"] },
        { name: "A700", value: cyan["A700"] },
    ],
};
export const tealPalette: ColorPaletteDefinition = {
    hue: "Teal",
    colors: [
        //{name:"100",value:teal[100]},
        //{name:"200",value:teal[200]},
        { name: "300", value: teal[300] },
        { name: "400", value: teal[400] },
        { name: "500", value: teal[500] },
        { name: "600", value: teal[600] },
        { name: "700", value: teal[700] },
        { name: "800", value: teal[800] },
        { name: "900", value: teal[900] },
    ],
    accents: [
        { name: "A200", value: teal["A200"] },
        { name: "A400", value: teal["A400"] },
        { name: "A700", value: teal["A700"] },
    ],
};
export const greenPalette: ColorPaletteDefinition = {
    hue: "Green",
    colors: [
        //{name:"100",value:green[100]},
        //{name:"200",value:green[200]},
        { name: "300", value: green[300] },
        { name: "400", value: green[400] },
        { name: "500", value: green[500] },
        { name: "600", value: green[600] },
        { name: "700", value: green[700] },
        { name: "800", value: green[800] },
        { name: "900", value: green[900] },
    ],
    accents: [
        { name: "A200", value: green["A200"] },
        { name: "A400", value: green["A400"] },
        { name: "A700", value: green["A700"] },
    ],
};
export const lightGreenPalette: ColorPaletteDefinition = {
    hue: "Light green",
    colors: [
        //{name:"100",value:lightGreen[100]},
        //{name:"200",value:lightGreen[200]},
        { name: "300", value: lightGreen[300] },
        { name: "400", value: lightGreen[400] },
        { name: "500", value: lightGreen[500] },
        { name: "600", value: lightGreen[600] },
        { name: "700", value: lightGreen[700] },
        { name: "800", value: lightGreen[800] },
        { name: "900", value: lightGreen[900] },
    ],
    accents: [
        { name: "A200", value: lightGreen["A200"] },
        { name: "A400", value: lightGreen["A400"] },
        { name: "A700", value: lightGreen["A700"] },
    ],
};
export const limePalette: ColorPaletteDefinition = {
    hue: "Lime",
    colors: [
        //{name:"100",value:lime[100]},
        //{name:"200",value:lime[200]},
        { name: "300", value: lime[300] },
        { name: "400", value: lime[400] },
        { name: "500", value: lime[500] },
        { name: "600", value: lime[600] },
        { name: "700", value: lime[700] },
        { name: "800", value: lime[800] },
        { name: "900", value: lime[900] },
    ],
    accents: [
        { name: "A200", value: lime["A200"] },
        { name: "A400", value: lime["A400"] },
        { name: "A700", value: lime["A700"] },
    ],
};
export const yellowPalette: ColorPaletteDefinition = {
    hue: "Yellow",
    colors: [
        //{name:"100",value:yellow[100]},
        //{name:"200",value:yellow[200]},
        { name: "300", value: yellow[300] },
        { name: "400", value: yellow[400] },
        { name: "500", value: yellow[500] },
        { name: "600", value: yellow[600] },
        { name: "700", value: yellow[700] },
        { name: "800", value: yellow[800] },
        { name: "900", value: yellow[900] },
    ],
    accents: [
        { name: "A200", value: yellow["A200"] },
        { name: "A400", value: yellow["A400"] },
        { name: "A700", value: yellow["A700"] },
    ],
};
export const amberPalette: ColorPaletteDefinition = {
    hue: "Amber",
    colors: [
        //{name:"100",value:amber[100]},
        //{name:"200",value:amber[200]},
        { name: "300", value: amber[300] },
        { name: "400", value: amber[400] },
        { name: "500", value: amber[500] },
        { name: "600", value: amber[600] },
        { name: "700", value: amber[700] },
        { name: "800", value: amber[800] },
        { name: "900", value: amber[900] },
    ],
    accents: [
        { name: "A200", value: amber["A200"] },
        { name: "A400", value: amber["A400"] },
        { name: "A700", value: amber["A700"] },
    ],
};
export const orangePalette: ColorPaletteDefinition = {
    hue: "Orange",
    colors: [
        //{name:"100",value:orange[100]},
        //{name:"200",value:orange[200]},
        { name: "300", value: orange[300] },
        { name: "400", value: orange[400] },
        { name: "500", value: orange[500] },
        { name: "600", value: orange[600] },
        { name: "700", value: orange[700] },
        { name: "800", value: orange[800] },
        { name: "900", value: orange[900] },
    ],
    accents: [
        { name: "A200", value: orange["A200"] },
        { name: "A400", value: orange["A400"] },
        { name: "A700", value: orange["A700"] },
    ],
};
export const deepOrangePalette: ColorPaletteDefinition = {
    hue: "Deep orange",
    colors: [
        //{name:"100",value:deepOrange[100]},
        //{name:"200",value:deepOrange[200]},
        { name: "300", value: deepOrange[300] },
        { name: "400", value: deepOrange[400] },
        { name: "500", value: deepOrange[500] },
        { name: "600", value: deepOrange[600] },
        { name: "700", value: deepOrange[700] },
        { name: "800", value: deepOrange[800] },
        { name: "900", value: deepOrange[900] },
    ],
    accents: [
        { name: "A200", value: deepOrange["A200"] },
        { name: "A400", value: deepOrange["A400"] },
        { name: "A700", value: deepOrange["A700"] },
    ],
};
export const brownPalette: ColorPaletteDefinition = {
    hue: "Brown",
    colors: [
        //{name:"100",value:brown[100]},
        //{name:"200",value:brown[200]},
        { name: "300", value: brown[300] },
        { name: "400", value: brown[400] },
        { name: "500", value: brown[500] },
        { name: "600", value: brown[600] },
        { name: "700", value: brown[700] },
        { name: "800", value: brown[800] },
        { name: "900", value: brown[900] },
    ],
    accents: [],
};
export const greyPalette: ColorPaletteDefinition = {
    hue: "Grey",
    colors: [
        //{name:"100",value:grey[100]},
        //{name:"200",value:grey[200]},
        { name: "300", value: grey[300] },
        { name: "400", value: grey[400] },
        { name: "500", value: grey[500] },
        { name: "600", value: grey[600] },
        { name: "700", value: grey[700] },
        { name: "800", value: grey[800] },
        { name: "900", value: grey[900] },
    ],
    accents: [],
};
export const blueGreyPalette: ColorPaletteDefinition = {
    hue: "Blue grey",
    colors: [
        //{name:"100",value:blueGrey[100]},
        //{name:"200",value:blueGrey[200]},
        { name: "300", value: blueGrey[300] },
        { name: "400", value: blueGrey[400] },
        { name: "500", value: blueGrey[500] },
        { name: "600", value: blueGrey[600] },
        { name: "700", value: blueGrey[700] },
        { name: "800", value: blueGrey[800] },
        { name: "900", value: blueGrey[900] },
    ],
    accents: [],
};
export const colorPalettes: ColorPaletteDefinition[] = [
    redPalette,
    deepOrangePalette,
    orangePalette,
    amberPalette,
    yellowPalette,
    limePalette,
    lightGreenPalette,
    greenPalette,
    tealPalette,
    cyanPalette,
    lightBluePalette,
    bluePalette,
    indigoPalette,
    deepPurplePalette,
    purplePalette,
    pinkPalette,
    brownPalette,
    blueGreyPalette,
    greyPalette,
];