import * as React from "react";
import { ISettingsContext, SettingsProviderProps } from "./types";
import { useAuthenticated } from "../AuthenticatedContext";
import { NoProviderError } from "../../types";

const SettingsContext = React.createContext<ISettingsContext>({
    clearAllSettings: () => { throw new NoProviderError() },
    deleteSetting: () => { throw new NoProviderError() },
    getBooleanOrFallback: () => { throw new NoProviderError() },
    getNumberOrFallback: () => { throw new NoProviderError() },
    getStringOrFallback: () => { throw new NoProviderError() },
    getBooleanOrUndefined: () => { throw new NoProviderError() },
    getNumberOrUndefined: () => { throw new NoProviderError() },
    getStringOrUndefined: () => { throw new NoProviderError() },
    setBoolean: () => { throw new NoProviderError() },
    setNumber: () => { throw new NoProviderError() },
    setString: () => { throw new NoProviderError() },
});

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
    const { authedMember } = useAuthenticated();

    const keyPrefix = React.useCallback((): string => {
        return authedMember ? `${(authedMember.id).replaceAll("-", "")}:` : "";
    }, [authedMember]);

    const clearAllSettings = React.useCallback(() => {
        try {
            for (let i: number = localStorage.length - 1; i < 0; i--) {
                const key = localStorage.key(i);
                if (key && key.length > keyPrefix().length && key.substring(0, keyPrefix().length) === keyPrefix()) {
                    localStorage.removeItem(key)
                }
            }
        }
        catch (ex) {
            console.warn("SettingsProvider.clear: %O", ex);
        }
    }, [keyPrefix]);

    const deleteSetting = React.useCallback((key: string) => {
        localStorage.removeItem(keyPrefix() + key);
    }, [keyPrefix]);
    const getStringOrUndefined = React.useCallback((key: string): string | undefined => {
        try {
            const value = localStorage.getItem(keyPrefix() + key);
            return value === null ? undefined : value;
        }
        catch (ex) {
            console.warn("SettingsProvider.getStringOrUndefined: %O", ex);
            return undefined;
        }
    }, [keyPrefix]);

    const getBooleanOrUndefined = React.useCallback((key: string): boolean | undefined => {
        const value = getStringOrUndefined(key);
        if (value === "true") return true;
        if (value === "false") return false;
        return undefined;
    }, [getStringOrUndefined]);

    const getBooleanOrFallback = React.useCallback((key: string, fallback: boolean): boolean => {
        const value = getBooleanOrUndefined(key);
        return value === undefined ? fallback : value;
    }, [getBooleanOrUndefined]);

    const getNumberOrUndefined = React.useCallback((key: string): number | undefined => {
        const value = getStringOrUndefined(key);
        try {
            if (!value) return undefined;
            return +value;
        }
        catch (ex) {
            console.warn("SettingsProvider.getNumberOrUndefined: %O", ex);
            return undefined;
        }
    }, [getStringOrUndefined]);

    const getNumberOrFallback = React.useCallback((key: string, fallback: number): number => {
        const value = getNumberOrUndefined(key);
        return value ? value : fallback;
    }, [getNumberOrUndefined]);

    const getStringOrFallback = React.useCallback((key: string, fallback: string): string => {
        const value = getStringOrUndefined(key);
        return value ? value : fallback;
    }, [getStringOrUndefined]);

    const setString = React.useCallback((key: string, value: string) => {
        try {
            localStorage.setItem(keyPrefix() + key, value);
            return true
        }
        catch (ex) {
            console.warn("SettingsProvider.setString: %O", ex);
            return false;
        }
    }, [keyPrefix]);

    const setBoolean = React.useCallback((key: string, value: boolean) => {
        setString(key, String(value));
    }, [setString]);

    const setNumber = React.useCallback((key: string, value: number) => {
        setString(key, String(value));
    }, [setString]);

    return (
        <SettingsContext.Provider value={{
            clearAllSettings,
            deleteSetting,
            getBooleanOrFallback,
            getBooleanOrUndefined,
            getNumberOrFallback,
            getNumberOrUndefined,
            getStringOrFallback,
            getStringOrUndefined,
            setBoolean,
            setNumber,
            setString,
        }}>
            {children}
        </SettingsContext.Provider>
    );
}

const useSettings = () => React.useContext(SettingsContext);
export { useSettings, SettingsProvider };