import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { VerbosityLevel } from "../types";


export class SnackbarHelper {
    public static maybeShowMessage = (bar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey, verbosityLevel: VerbosityLevel, message: SnackbarMessage, flavour: "error" | "success" | "warning"): SnackbarKey => {
        if (verbosityLevel === VerbosityLevel.None) return 0;
        if (verbosityLevel === VerbosityLevel.ErrorOnly && flavour === "success") return 0;

        return bar(message, { variant: flavour });
    }
}