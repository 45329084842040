import { SnippetsProviderProps } from "./types";
import * as React from "react";
import { ISnippetsContext } from "./types";
import { useSession } from "../SessionContext";
import { CoreCloudManager } from "../../services/CloudManager/CoreCloudManager";
import { NoProviderError } from "../../types";

const SnippetsContext = React.createContext<ISnippetsContext>({
    getSnippet: () => { throw new NoProviderError() },
});


const SnippetsProvider: React.FC<SnippetsProviderProps> = ({ children }) => {
    const { getValidAccessToken } = useSession();

    const coreCloudManager = React.useRef<CoreCloudManager>();


    React.useEffect(() => {
        coreCloudManager.current = new CoreCloudManager(getValidAccessToken);
    }, [getValidAccessToken]);

    const getSnippet = React.useCallback(async (snippetId: string): Promise<string | undefined> => {
        if (!coreCloudManager.current) return undefined;
        const result = await coreCloudManager.current.getSnippet(snippetId);
        if (result.isOk) {
            return result.response;
        }
    }, []);
    return (
        <SnippetsContext.Provider value={{ getSnippet }}>
            {children}
        </SnippetsContext.Provider>
    );
}

const useSnippets = () => React.useContext(SnippetsContext);
export { useSnippets, SnippetsProvider }