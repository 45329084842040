// import { isLiveEnvironment } from "../../Globals";
import { TokenHelper } from "../../helpers/TokenHelper";
import { Jwt, TokenResponse } from "../../services/ApiClient/Schema";
import { CoreCloudManager } from "../../services/CloudManager/CoreCloudManager";
import { addMilliseconds, isAfter, differenceInMilliseconds } from "date-fns";
import { PersistedToken, SessionManagerBroadcastMessage, SessionStatus, SessionStatusChangeMessage } from "./types";
import { ErrorHelper } from "../../helpers/ErrorHelper";
// import { deepPurple } from "@material-ui/core/colors";


const refreshTokenStorageKey = "_refresh";
const maybeLogMessage = (message: string, ...args: any[]) => {
    // if (!isLiveEnvironment) {
    //     const css = `display: inline-block; padding: 4px 8px; margin: 4px 0; border-radius: 2px; background-color: ${deepPurple[400]}; color: #ffffff; border-left: 8px solid rgba(0, 0, 0, 0.5);`;
    //     console.log(`%c🚧 ${message} [${new Date().toLocaleString()}]`, css, ...args);
    // }
}



export class SessionManager {
    /** reference to the current running timer s we can clear timers on unloading */
    private _timeoutId: number = 0;
    private _retryCount: number = 0;
    private _timerMilliseconds: number;
    private _sessionStatus: SessionStatus = "authenticated";
    private _accessTokenLocalExpiryDate: Date = new Date(0);
    private _accessToken: string | undefined = undefined;
    private _accessJwt: Jwt | undefined = undefined;
    private _refreshToken: string | undefined = undefined;
    private _requestAccessTokenFromAnotherContextTimeoutId: number = 0;
    private _refreshJwt: Jwt | undefined = undefined;
    private _broadcastChannel: BroadcastChannel;
    private _coreCloudManager: CoreCloudManager;
    /** Is token to be persisted in localStorage */
    private _persist: boolean = false;

    constructor() {
        // introduce a bit of randomness to the timer to try to reduce collisions
        this._timerMilliseconds = 60000 + (Math.ceil(Math.random() * 30) * 1000)
        maybeLogMessage(`SessionManager constructor() _timer refresh=${this._timerMilliseconds / 1000}s`);

        // set up a broadcast channel to communicate with other tabs
        this._broadcastChannel = new BroadcastChannel("SessionManager.BroadcastChannel");

        // listen for messages from other tabs
        this._broadcastChannel.onmessage = (event: MessageEvent) => {
            const message: SessionManagerBroadcastMessage = event.data;
            maybeLogMessage("SessionManager: <<-- incoming message ✉", message);
            // a new window/tab has been opened
            if (message.name === "requestAccessTokenFromAnotherContext") {
                if (this._refreshJwt && this._accessJwt && this._accessToken && this._refreshToken && isAfter(this._accessTokenLocalExpiryDate, new Date())) {
                    const outgoingMessage: SessionManagerBroadcastMessage =
                    {
                        name: "responseAccessTokenFromAnotherContext",
                        payload: {
                            accessJwt: this._accessJwt,
                            accessToken: this._accessToken,
                            accessTokenLocalExpiryDate: this._accessTokenLocalExpiryDate,
                            persist: this._persist,
                            refreshJwt: this._refreshJwt,
                            refreshToken: this._refreshToken,
                            status: this._sessionStatus,
                        }
                    }
                    maybeLogMessage("SessionManager: -->> outgoing message ✉", outgoingMessage);
                    this._broadcastChannel.postMessage(outgoingMessage);
                }
            } else if (message.name === "responseAccessTokenFromAnotherContext" || message.name === "refreshedToken") {
                this._refreshJwt = message.payload!.refreshJwt;
                this._refreshToken = message.payload!.refreshToken;
                this._accessJwt = message.payload!.accessJwt;
                this._accessToken = message.payload!.accessToken;
                this._refreshJwt = message.payload!.refreshJwt;
                this._accessTokenLocalExpiryDate = message.payload!.accessTokenLocalExpiryDate;
                if (this._sessionStatus !== message.payload!.status) this._setStatus(message.payload!.status);
            } else if (message.name === "signedOut") {
                this._unsetTokens();
                this._setStatus("unauthenticated");
            }
        }

        this._requestAccessTokenFromAnotherContextTimeoutId = window.setTimeout(() => this._broadcastChannel.postMessage({ name: "requestAccessTokenFromAnotherContext" } as SessionManagerBroadcastMessage), 100);


        this._coreCloudManager = new CoreCloudManager(this.getValidAccessToken)

        const persistedToken = this._getRefreshTokenFromStorage();
        if (persistedToken) {
            maybeLogMessage("SessionManager constructor() persisted token 😀");
            this._refreshToken = persistedToken.token;
            this._refreshJwt = TokenHelper.decode(persistedToken.token);
            this._persist = persistedToken.persist;
            // schedule a call to ge the access token immediately
            this._setStatus("authenticating");


            window.clearTimeout(this._timeoutId);
            window.setTimeout(() => this._maybePerformTokenRefresh(), 20);

        } else {
            maybeLogMessage("SessionManager constructor() no persisted token");
            this._setStatus("unauthenticated");
            window.clearTimeout(this._timeoutId);
        }
    }

    public destructor() {
        // this gets triggered by hot-reloads whilst in dev mode se we will skip it
        if (process.env.NODE_ENV === "production") {
            maybeLogMessage("SessionManager destructor()");
            this._broadcastChannel.close();
            window.clearTimeout(this._timeoutId);
        }
    }

    public trySilentSignOut = async () => {
        window.clearTimeout(this._timeoutId);
        window.clearTimeout(this._requestAccessTokenFromAnotherContextTimeoutId);
        if (this._refreshToken) {
            const result = await this._coreCloudManager.signOut(this._refreshToken);
            if (!result.isOk) {
                console.warn("SessionManager.trySilentSignOut failed", result.exception);
            }
        }
        this._unsetTokens();
        this._setStatus("unauthenticated");
        const outgoingMessage: SessionManagerBroadcastMessage = { name: "signedOut" };
        maybeLogMessage("SessionManager: -->> outgoing message ✉", outgoingMessage);
        this._broadcastChannel.postMessage(outgoingMessage);
    }


    public signOut = async (): Promise<{ success: boolean, errorMessage?: string | undefined }> => {
        if (!this._refreshToken)
            throw new Error("signOut: _refreshToken is undefined");

        const result = await this._coreCloudManager.signOut(this._refreshToken);
        if (result.isOk) {
            window.clearTimeout(this._timeoutId)
            this._unsetTokens();
            this._setStatus("unauthenticated");

            const outgoingMessage: SessionManagerBroadcastMessage = { name: "signedOut" };
            maybeLogMessage("SessionManager: -->> outgoing message ✉", outgoingMessage);
            this._broadcastChannel.postMessage(outgoingMessage);
            return { success: true };
        } else if (!result.isAborted) {
            const errorMessage = ErrorHelper.getErrorMessageForSnackbar("Failed to sign out.", result.exception);
            return { success: false, errorMessage: errorMessage };
        } else {
            return { success: false, errorMessage: "Failed to sign out. Request was aborted." };
        }
    }

    public signIn = async (email: string, password: string, persist: boolean): Promise<{ success: boolean, errorMessage?: string | undefined }> => {
        this._persist = persist;
        const result = await this._coreCloudManager.signIn({ email: email, password: password, workspaceId: undefined, appId: process.env.REACT_APP_API_APP_ID!.trim() });
        if (result.isOk) {
            this._processSuccessfulTokenRefresh(result.response!);
            return { success: true };
        } else if (!result.isAborted) {
            const errorMessage = ErrorHelper.getErrorMessageForSnackbar("Failed to sign in.", result.exception);
            return { success: false, errorMessage: errorMessage };
        } else {
            return { success: false, errorMessage: "Failed to sign in. Request was aborted." };
        }
    }

    public changeWorkspace = async (workspaceId: string): Promise<{ success: boolean, errorMessage?: string | undefined }> => {
        if (!this._refreshToken)
            throw new Error("changeWorkspace: _refreshToken is undefined");

        const result = await this._coreCloudManager.refreshToken({ refreshToken: this._refreshToken, workspaceId: workspaceId });
        if (result.isOk) {
            this._processSuccessfulTokenRefresh(result.response!);
            return { success: true };
        } else if (!result.isAborted) {
            const errorMessage = ErrorHelper.getErrorMessageForSnackbar("Failed to change workspace.", result.exception);
            return { success: false, errorMessage: errorMessage };
        } else {
            return { success: false, errorMessage: "Failed to change workspace. Request was aborted." };
        }
    }

    public changePassword = async (oldPassword: string, newPassword: string): Promise<{ success: boolean, errorMessage?: string | undefined }> => {
        const result = await this._coreCloudManager.changePasswordForAuthedUser(oldPassword, newPassword);
        if (result.isOk) {
            this._processSuccessfulTokenRefresh(result.response!);
            return { success: true };
        } else if (!result.isAborted) {
            const errorMessage = ErrorHelper.getErrorMessageForSnackbar("Failed to change password.", result.exception);
            return { success: false, errorMessage: errorMessage };
        } else {
            return { success: false, errorMessage: "Failed to change password. Request was aborted." };
        }
    }

    public getValidAccessToken = async (): Promise<string> => {
        if (!this._refreshToken) {
            maybeLogMessage("SessionManager.getValidAccessToken failed because !this._refreshToken");
            return "";
        }
        if (!this._accessToken) {
            maybeLogMessage("SessionManager.getValidAccessToken failed because !this._refreshToken");
            return "";
        }

        //Is the first date after the second one?
        if (isAfter(new Date(), this._accessTokenLocalExpiryDate)) {
            maybeLogMessage("SessionManager.getValidAccessToken awaiting a new token");
            window.clearTimeout(this._timeoutId);
            await this._maybePerformTokenRefresh();
        }
        // has token expired?
        //maybeLogMessage("SessionManager.getValidAccessToken returning", this._accessToken.substring(0, 100));
        return this._accessToken;
    }

    private _processSuccessfulTokenRefresh = (tokenResponse: TokenResponse) => {
        this._setRefreshToken({ token: tokenResponse.refreshToken, persist: this._persist });
        this._setAccessToken(tokenResponse.accessToken);
        this._setStatus("authenticated");
        const outgoingMessage: SessionManagerBroadcastMessage = {
            name: "refreshedToken",
            payload: {
                accessJwt: this._accessJwt!,
                refreshJwt: this._refreshJwt!,
                accessTokenLocalExpiryDate: this._accessTokenLocalExpiryDate,
                persist: this._persist,
                status: this._sessionStatus,
                accessToken: this._accessToken!,
                refreshToken: this._refreshToken!,
            }
        }
        maybeLogMessage("SessionManager: -->> outgoing message ✉", outgoingMessage);
        this._broadcastChannel.postMessage(outgoingMessage);
    }

    private _setStatus = (value: SessionStatus) => {
        this._sessionStatus = value;
        window.postMessage({
            source: "SessionStatusChangeMessage",
            payload: {
                status: value,
                workspaceId: this._refreshJwt?.workspaceId,
            },
        } as SessionStatusChangeMessage)
    }

    private _getRefreshTokenFromStorage = (): PersistedToken | undefined => {
        let persist = false;
        let refreshToken: string | null = null;
        refreshToken = sessionStorage.getItem(refreshTokenStorageKey);
        if (!refreshToken) {
            refreshToken = localStorage.getItem(refreshTokenStorageKey);
            if (refreshToken) persist = true;
        }
        return refreshToken ? { token: refreshToken, persist: persist } : undefined;
    }

    private _setRefreshToken = (persistedToken: PersistedToken) => {
        this._refreshToken = persistedToken.token;
        this._refreshJwt = TokenHelper.decode(persistedToken.token);
        this._persist = persistedToken.persist;
        const storage: Storage = persistedToken.persist ? localStorage : sessionStorage;
        storage.setItem(refreshTokenStorageKey, persistedToken.token);
    }

    /** to be used when a fresh access token has been fetched from the web service */
    private _setAccessToken = (token: string, localExpiryDate?: Date) => {
        this._accessToken = token;
        this._accessJwt = TokenHelper.decode(token);

        if (!localExpiryDate) {
            // duration that the token is valid for
            const ms = differenceInMilliseconds(this._accessJwt!.exp!, this._accessJwt!.iat);
            const localExpiryDate2 = addMilliseconds(new Date(), ms);
            this._accessTokenLocalExpiryDate = localExpiryDate2;
        }

        window.clearTimeout(this._timeoutId);
        window.setTimeout(() => this._maybePerformTokenRefresh(), this._timerMilliseconds);
        // this._scheduleTokenRefresh(localExpiryDate);
    }

    /** clear refresh token from storage and access token from memory */
    private _unsetTokens = () => {
        sessionStorage.removeItem(refreshTokenStorageKey);
        localStorage.removeItem(refreshTokenStorageKey);
        this._accessJwt = undefined;
        this._accessToken = undefined;
        this._refreshJwt = undefined;
        this._refreshToken = undefined;
    }

    private _maybePerformTokenRefresh = async () => {
        await navigator.locks.request("SessionManager._performTokenRefreshWithLock", async (lock) => {
            maybeLogMessage("SessionManager._maybePerformTokenRefresh starting up");
            if (!this._refreshJwt)
                throw new Error("performTokenRefresh: _refreshJwt is undefined");

            if (!this._refreshToken)
                throw new Error("performTokenRefresh: _refreshToken is undefined");

            // skip processing if the token will have not expired before the next time this is called
            const nextRunTime = addMilliseconds(new Date(), this._timerMilliseconds)
            //Is the first date after the second one?
            if (isAfter(this._accessTokenLocalExpiryDate, nextRunTime)) {
                maybeLogMessage("SessionManager._maybePerformTokenRefresh bailing out as too soon", this._accessTokenLocalExpiryDate, this._accessJwt);
                this._setStatus("authenticated");
                return;
            }

            maybeLogMessage("SessionManager._maybePerformTokenRefresh going to call await this._coreCloudManager.refreshToken()");
            const result = await this._coreCloudManager.refreshToken({ refreshToken: this._refreshToken, workspaceId: this._refreshJwt.workspaceId, });
            maybeLogMessage("SessionManager_maybePerformTokenRefresh refreshToken result", result);
            if (result.isOk) {
                this._retryCount = 0;
                this._processSuccessfulTokenRefresh(result.response!);
                return true;
                // } else if (result.isAborted) {
                //     // what to do
            } else {
                let customTimerMilliseconds: number = 0;
                maybeLogMessage(`${result.exception?.status} error when performing refreshToken`,);
                if (result.exception && result.exception.status === 400) {// can we ignore this?
                    // 400: race conditions refresh token gone by the time api saves data
                    //      -- maybe a refresh would fix?
                } else if (result.exception && (result.exception.status === 401 || result.exception.status === 403 || result.exception.status === 500)) {
                    // 401: invalid access token - server side JWT issuing key could have been modified, invalidating existing tokens for ALL users
                    // 401: refresh token invalid, expired or revoked
                    // 401: invalid app id or app has been disabled
                    // 401: workspace member disabled for the workspace
                    // -- new log in required
                    this._unsetTokens();
                    this._setStatus("unauthenticated");
                    //const errorMessage = `An error occurred when trying to refresh your security token. ${result.exception.message}`;
                    //SnackbarHelper.maybeShowMessage(enqueueSnackbar, VerbosityLevel.ErrorOnly, errorMessage, "error");
                } else if (result.exception && result.exception.status === 429) {
                    this._retryCount += 1;
                    maybeLogMessage(`refreshToken nextRetryDelay 30 seconds (Error 429)`, "#ffffff");
                    customTimerMilliseconds = 30000;
                    this._setStatus("authenticating");
                    window.clearTimeout(this._timeoutId);
                    this._timeoutId = window.setTimeout(() => this._maybePerformTokenRefresh(), customTimerMilliseconds);
                } else {
                    // maybe network connectivity / FAILED_TO_FETCH
                    if (this._retryCount < 6) {
                        // 1 sec, 2 sec, 4 sec, 8 sec, 16 sec ,32 sec
                        const seconds = Math.pow(2, this._retryCount);
                        maybeLogMessage(`refreshToken nextRetryDelay ${seconds} seconds`);
                        customTimerMilliseconds = 1000 * seconds;
                    } else {
                        maybeLogMessage(`refreshToken nextRetryDelay 1 minute`);
                        customTimerMilliseconds = 60000;
                    }
                    this._retryCount += 1;
                    this._setStatus("authenticating");
                    window.clearTimeout(this._timeoutId);
                    this._timeoutId = window.setTimeout(() => this._maybePerformTokenRefresh(), customTimerMilliseconds);
                }
            }
            return false;
        });
    }
}