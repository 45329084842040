import Paper, { PaperProps } from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Draggable from "react-draggable";

const DraggablePaper = (props: PaperProps) => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Draggable
            bounds="body"
            disabled={!smUp}
            cancel={'input, button, [class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export { DraggablePaper };