import { CultureInfoResponse } from "../../services/ApiClient/Schema";

export interface ILocalizationContext {
    /**
     * Set the localization info for the app. If parameters are not recognize
     * fallback values will be used.
     * @param localeString locale string e.g. en-GB, en-US
     * @param ianaTimeZone IANA time zone e.g. America/Los_Angeles, Europe/Paris
     * @returns 
     */
    setLocale: (localeString: string, ianaTimeZone: string) => void;
    currentLocale: LocaleInfo;

    /** 
     * format the given UTC date using the regional formatting for the locale 
     * and the IANA time zone
     */
    formatDate: (date: number | Date, dateformat: DateFormat, ignoreTimeZone?: boolean | undefined) => string;
    /**
     * If date is yesterday, today, tomorrow return a localized word.
     * If date is less than a week ago return localized day name.
     * Otherwise return a localized long date.
     * @param date 
     * @returns 
     */
    getRelativeDateName: (date: Date) => string
    getTimeZoneOffset: (date: Date) => number;
    /** takes a date in the devices time zone adjusts for the workspace timezone and return a UTC representation */
    getOutgoingAdjustedDate: (date: Date) => Date;
    getIncomingAdjustedDate: (date: Date) => Date;

    cultureInfos: CultureInfoResponseExt[];
    // timeZones: TimeZoneResponseExt[];
    // /**
    //  * 
    //  * @param id can be windows id or iana id
    //  * @returns 
    //  */
    // getTimeZone: (id: string) => TimeZoneResponseExt | undefined;
    getCultureInfo: (id: string) => CultureInfoResponseExt | undefined;
    /**
     * Attempts to fetch the current device regional formatting from the published
     * CultureInfos. If not supported a fallback value is given
     * @returns 
     */
    getDeviceCultureInfoOrFallback: () => CultureInfoResponseExt;
}

export interface LocalizationProviderProps {
    children?: React.ReactNode
};


export enum DateFormat {
    /** 15/06/2009 equivalent to .Net "d" */
    ShortDate = "P",
    /** 15 June 2009 */
    MediumDate = "PPP",
    /** 15 June 2009 */
    MediumDateShortTime = "PPP p",
    /** Monday, 15 June 2009 equivalent to .Net "D" */
    LongDate = "PPPP",
    /** Jan equivalent to .Net "MMM" */
    ShortMonth = "MMM",
    /** January equivalent to .Net "MMMM" */
    LongMonth = "MMMM",
    /** Jan 05 equivalent to .Net "MMM" */
    ShortMonthYear = "MMM yy",
    /** January 2005 equivalent to .Net "MMMM" */
    LongMonthYear = "MMMM yyyy",
    /** 15/06/2009, 13:25 */
    ShortDateTime = "Pp",
    /** Monday, 15 June 2009, 13:25:21 equivalent to .Net "F" */
    LongDateTime = "PPPP pp",
    /** Monday, 15 June 2009, 13:25 */
    LongDateShortTime = "PPPP p",
    /** 13:25 equivalent to .Net "t" */
    ShortTime = "p",
    /** 13:25:12 equivalent to .Net "T" */
    LongTime = "pp",
    /** Mon equivalent to .Net "ddd" */
    ShortDay = "eee",
    /** Monday equivalent to .Net "dddd" */
    LongDay = "eeee",
    /** Monday 13:25  */
    LongDayShortTime = "eeee",
}
export interface LocaleInfo {
    /** locale string e.g. en-GB, en-US */
    localeString: string;
    /** relative folder path containing localized resources based on locale */
    localeFolder: string;
    dateLocale: Locale;
    /** IANA time zone for the current authed user from API e.g. America/Los_Angeles, Europe/Paris */
    ianaTimeZone: string;
    /** dd/MM/yyyy */
    datePickerFormat: string
    /** IANA time zone fro the current device e.g. America/Los_Angeles, Europe/Paris */
    deviceIanaTimeZone: string;
}

export interface CultureInfoResponseExt extends CultureInfoResponse {
    dateFolder?: string | undefined;
    languageFolder?: string | undefined;
    published?: boolean | undefined;
    datePickerFormat: string | undefined;
}