import { amber, blue, blueGrey, green, grey, lightBlue, lightGreen, purple, red, teal, yellow } from "@material-ui/core/colors";
import { colorPalettes } from "./ColorPalettes";


export class ThemeHelper {

    public static isApple = (): boolean => {
        return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel');
    }

    public static getFolderColor = () => this.isApple() ? this.folderColorIos : this.folderColor;

    public static readonly buildProjexBlack = "#303335";

    public static readonly tooltipBackgroundColor = grey[700];  //alpha(ThemeHelper.buildProjexBlack, 0.95);//blueGrey[800];

    public static readonly formButtonMinWidth = 88;

    public static readonly defaultTagColor = grey[300];
    public static readonly defaultGanttTaskColor = blue[600];
    public static readonly defaultAnnotationColor = lightGreen["A400"];
    public static readonly defaultProjectColor = green[700]

    public static readonly defaultMemberColor = teal[500];
    public static readonly defaultMemberTagColor = teal[300]; // 2 shades lighter

    public static readonly defaultGuestColor = purple[500];
    public static readonly defaultGuestTagColor = purple[300]; // 2 shades lighter

    public static readonly unknownMemberColor = blueGrey[300];
    public static readonly folderColor = yellow[700];
    public static readonly folderColorIos = lightBlue[200];

    public static readonly archivedChannelColor = amber["A200"];
    public static readonly otherMembersChannelColor = red["A200"];
    public static readonly hiddenChannelColor = blue["A200"];

    public static readonly defaultInputVariant: "outlined" | "filled" = "outlined";
    public static readonly defaultAvatarVariant: "circular" | "rounded" | "square" = "circular";
    //public static readonly GridSpacingForm = 3;
    //public static readonly GridSpacingLayout = 2;
    public static readonly messageMaxWidth = 848;// "53rem";
    public static readonly transparentPixelDataUri = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";


    public static getRandomColor = (): string => {
        const i = Math.floor(Math.random() * (colorPalettes.length - 1)); // skip selecting from this.greyPalette
        const j = Math.floor(Math.random() * 5);
        return [...colorPalettes[i].colors].reverse()[j].value; // choose from 5 darkest variants
    }
}