export class ApiClientBase {
    public requireToken: boolean | undefined;

    public accessTokenFactory?(): Promise<string>;

    /** regex pattern to test for dates formatted like 2021-08-20T14:43:56.312Z */
    public static apiDateFormat = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.?\d*)(Z$)/;

    public static dateReviver = ((key: string, value: any) => (typeof value === "string" && this.apiDateFormat.test(value)) ? new Date(value) : value)

    protected getBaseUrl = (defaultUrl: string, requestedUrl?: string) => process.env.REACT_APP_API_ENDPOINT!.trim();

    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        if (this.requireToken) {
            if (!this.accessTokenFactory)
                throw new Error("Request is configured to required an access token but accessTokenFactory is not set.");

            const token: string = await this.accessTokenFactory();
            options.headers = {
                ...options.headers,
                "Authorization": `bearer ${token}`,
            };
        }
        options.headers = {
            ...options.headers,
            "X-Client-Version": process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION.trim() : "0",
        };

        return Promise.resolve(options);
    }
}