import { Currency } from "./types"

const gbp: Currency = {
    code: "GBP",
    numericCode: "826",
    decimalPlaces: 2,
    name: "Pound sterling",
    symbol: "£",
    subUnitSymbol: "p",
}

const eur: Currency = {
    code: "EUR",
    numericCode: "978",
    decimalPlaces: 2,
    name: "Euro",
    symbol: "€",
    subUnitSymbol: "c",
}

const usd: Currency = {
    code: "USD",
    numericCode: "840",
    decimalPlaces: 2,
    name: "United States dollar",
    symbol: "$",
    subUnitSymbol: "¢",
}

export { gbp, eur, usd }