// import CameraAltRoundedIcon from "@material-ui/icons/CameraAltRounded";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
// import NewReleasesRoundedIcon from "@material-ui/icons/NewReleasesRounded";

export enum ChooseFileSourceOption {
    // camera = "camera",
    // dropbox = "dropbox",
    fileSystem = "fileSystem",
    // goolgeDrive = "goolgeDrive",
    // oneDrive = "oneDrive",
    upload = "upload",
};

export const ChooseFileSourceOptions = [
    {
        id: ChooseFileSourceOption.fileSystem,
        title: "File explorer",
        description: "Select files from your project's online folder storage",
        icon: <FolderRoundedIcon />,
        enabled: true,
    },
    {
        id: ChooseFileSourceOption.upload,
        title: "Upload",
        description: "Choose files from your device to upload",
        icon: <CloudUploadRoundedIcon />,
        enabled: true,
    },
    // {
    //     id: ChooseFileSourceOption.camera,
    //     title: "Camera",
    //     description: "Take a pic",
    //     icon: <CameraAltRoundedIcon />,
    //     enabled: true,
    // },
    // {
    //     id: ChooseFileSourceOption.dropbox,
    //     title: "Dropbox",
    //     description: "Coming soon???",
    //     icon: <NewReleasesRoundedIcon />,
    //     enabled: false,
    // },
    // {
    //     id: ChooseFileSourceOption.goolgeDrive,
    //     title: "GoogleDrive",
    //     description: "Coming soon???",
    //     icon: <NewReleasesRoundedIcon />,
    //     enabled: false,
    // },
    // {
    //     id: ChooseFileSourceOption.oneDrive,
    //     title: "OneDrive",
    //     description: "Coming soon???",
    //     icon: <NewReleasesRoundedIcon />,
    //     enabled: false,
    // },
];