import { Currency, CurrencyProviderProps, ICurrencyContext } from "./types";
import * as React from "react";
import { eur, gbp, usd } from "./currencies";
import { NoProviderError } from "../../types";

const CurrencyContext = React.createContext<ICurrencyContext>({
    currency: usd,
    getFormattedValue: () => { throw new NoProviderError() },
    setCurrency: () => { throw new NoProviderError() },
});

const CurrencyProvider: React.FC<CurrencyProviderProps> = ({ children }) => {
    const [currency, setCurrencyInternal] = React.useState<Currency>(usd);

    const setCurrency = React.useCallback((code: string) => {
        setCurrencyInternal(previous => {
            if (previous.code === code) return previous;
            if (code === "GBP") return gbp;
            if (code === "EUR") return eur;
            if (code === "USD") return usd;
            throw new Error(`Unhandled currency code ${code}`);
        });
    }, []);

    const getFormattedValue = React.useCallback((value: number, includeSymbol: boolean = true): string => {
        //if (!value) return `where is value? ${value} ...`;
        return (includeSymbol ? currency.symbol : "") + value.toLocaleString(undefined, { minimumFractionDigits: currency.decimalPlaces, maximumFractionDigits: currency.decimalPlaces });
    }, [currency]);

    return (
        <CurrencyContext.Provider value={{ currency, getFormattedValue, setCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
}

const useCurrency = () => React.useContext(CurrencyContext);

export { useCurrency, CurrencyProvider };