import { useTheme } from "@material-ui/core/styles";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { DialogIconProps } from "./types";

const DialogIcon = ({ icon, style }: DialogIconProps) => {
    const theme = useTheme();

    switch (icon) {
        case "error": return <ErrorRoundedIcon htmlColor={theme.palette.error.main} style={style} />;
        case "warning": return <WarningRoundedIcon htmlColor={theme.palette.warning.main} style={style} />;
        case "info": return <InfoRoundedIcon htmlColor={theme.palette.info.main} style={style} />;
        case "success": return <CheckCircleRoundedIcon htmlColor={theme.palette.success.main} style={style} />;
    }
}

export { DialogIcon }