import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const SvenLinearProgress = withStyles((theme) => ({
    root: {
        height: 2 * theme.shape.borderRadius,
        borderRadius: theme.shape.borderRadius,
    },
    bar: {
        borderRadius: theme.shape.borderRadius,
    },
}))(LinearProgress);

export { SvenLinearProgress }